import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.tourManagement;

const state = {
  status: false,
  filiales: [],
  extendedFiliale: [],
  productsReport: [],
  filteredDresses: [],
  dressSize: [],
  activeRoute: [],
  dressColors: [],
  routesForTrucks: [],
  routesWithPagination: [],
  routesWithPaginationStore: [],
  trucksByTourId: [],
  currentPage: 1,
  totalItems: 0,
  isOnTour: {},
  tourEditable: [],
  storeManagementOverview: [],
  saturdayWorkingDays: [],
  externalWorkingDays: [],
  custom_TruckStuffId: [],
  cachedRoutes: {},
  potentialToursByEmployeePlanId: [],
  routeByStorePagination: [],
  plannedDaysByEmployee: [],
};

const getters = {
  // getFiliales: (state) => {
  //   return state.filiales;
  // },
  // getExtendedFiliales: (state) => {
  //   return state.extendedFiliale;
  // },
  getPlannedDaysByEmployee: (state) => {
    return state.plannedDaysByEmployee
  },
  getTotalItemsForRoutes: (state) => {
    return state.totalItems;
  },
  getActiveRouteById: (state) => {
    return state.activeRoute;
  },
  getRoutersForTrucks: (state) => {
    return state.routesForTrucks
  },
  getTrucksByRouteId: (state) => {
    return state.trucksByTourId
  },
  getRoutesWithPagination: (state) => {
    return state.routesWithPagination
  },
  getRoutesWithPaginationStore: (state) => {
    return state.routesWithPaginationStore
  },
  getisOnTour: (state) => {
    return state.isOnTour
  },
  getEditTourEditable: (state) => {
    return state.tourEditable
  },
  getStoreManagementOverview: (state) => {
    return state.storeManagementOverview
  },
  getSaturdayWorkingHours: (state) => {
    return state.saturdayWorkingDays
  },
  getExternalWorkingDays: (state) => {
    return state.externalWorkingDays
  },
  getCustom_TruckStuffId: (state) => {
    return state.custom_TruckStuffId
  },
  getPotentialToursByEmployeePlanId: (state) => {
    return state.potentialToursByEmployeePlanId
  },
  getRouteByStorePagination: (state) => {
    return state.routeByStorePagination
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  // SET_FILIALES(state, latest) {
  //   state.filiales = latest;
  // },
  SET_TOUR_EDITABLE(state, latest) {
    state.tourEditable = latest
  },
  SET_EXTENDED_FILIALES(state, latest) {
    state.extendedFiliale = latest;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_TOTALITEMS_ROUTES(state, latest) {
    state.totalItems = latest;
  },
  SET_ACTIVEROUTE_BY_EMPLOYEEID(state, latest) {
    state.activeRoute = latest;
  },
  SET_ROUTES_FOR_TRUCKS(state, latest) {
    state.routesForTrucks = latest
  },
  SET_TRUCKS_BY_TOURID(state, latest) {
    state.trucksByTourId = latest
  },
  SET_ROUTES_WITH_PAGINATION(state, latest) {
    state.routesWithPagination = latest
  },
  SET_ROUTES_WITH_PAGINATION_STORE(state, latest) {
    state.routesWithPaginationStore = latest
  },
  SET_IS_EMPLOYEE_IN_TOUR(state, latest) {
    state.isOnTour = latest
  },
  SET_STORE_MANAGEMENT_OVERVIEW(state, latest) {
    state.storeManagementOverview = latest
  },
  SET_SATURDAY_WORKING_HOURS(state, latest) {
    state.saturdayWorkingDays = latest
  },
  SET_EXTERNAL_WORKING_DAYS(state, latest) {
    state.externalWorkingDays = latest
  },
  SET_CUSTOM_TRUCKSTYFFID(state, latest) {
    state.custom_TruckStuffId = latest
  },
  clearObject(state) {
    state.cachedRoutes = {};
  },
  SET_POTENCIAL_TOURS_BY_EMPLOYEEPLANNID(state, latest) {
    state.potentialToursByEmployeePlanId = latest
  },
  SET_ROUTE_BY_STORE_PAGINATION(state, latest) {
    state.routeByStorePagination = latest
  },
  SET_PLANNED_DAYS_BY_EMPLOYEE(state, latest) {
    state.plannedDaysByEmployee = latest
  },
};

const actions = {
  async loadPlannedDaysByEmployee({ commit }, {
    employeeUserId, startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/plannedDays_by_employeeUserId`, {
        params: {
          employeeUserId, startDate, endDate,

        },
      });
      commit('SET_PLANNED_DAYS_BY_EMPLOYEE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetPlannedDaysByEmployee({ commit }) {
    commit('SET_PLANNED_DAYS_BY_EMPLOYEE', []);
  },
  async loadRouteByPagination({ commit }, {
    storeGLN, startShift, endShift, tourName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/toursPagination`, {
        params: {
          storeGLN,
          startShift,
          endShift,
          tourName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_ROUTES_WITH_PAGINATION', response.data.items);
      // console.log('extended filiale', response.data.items);
      commit('SET_TOTALITEMS_ROUTES', response.data.totalItems);
      commit('SET_CURRENTPAGE', response.data.currentPage);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  // async loadRouteByPagination({ commit, state }, {
  //   storeGLN, startShift, endShift, tourName, pageNumber, pageSize,
  // }) {
  //   const cacheKey = `${storeGLN}-${startShift}-${endShift}-${tourName}-${pageNumber}-${pageSize}`;

  //   if (state.cachedRoutes[cacheKey]) {
  //     // Data is already cached, retrieve it from the cache
  //     const cachedData = state.cachedRoutes[cacheKey];
  //     commit('SET_ROUTES_WITH_PAGINATION', cachedData.items);
  //     commit('SET_CURRENTPAGE', cachedData.currentPage);
  //     commit('SET_TOTALITEMS_ROUTES', cachedData.totalItems);
  //   } else {
  //     // Data is not cached, make a new request
  //     const response = await axios.get(`${domain}/toursPagination`, {
  //       params: {
  //         storeGLN,
  //         startShift,
  //         endShift,
  //         tourName,
  //         pageNumber,
  //         pageSize,
  //       },
  //     });

  //     // Cache the response data for future use
  //     const dataToCache = {
  //       items: response.data.items,
  //       currentPage: response.data.currentPage,
  //       totalItems: response.data.totalItems,
  //     };
  //     state.cachedRoutes[cacheKey] = dataToCache;

  //     // Update the Vuex store with the new data
  //     commit('SET_ROUTES_WITH_PAGINATION', response.data.items);
  //     commit('SET_CURRENTPAGE', response.data.currentPage);
  //     commit('SET_TOTALITEMS_ROUTES', response.data.totalItems);
  //   }
  // },
  // eslint-disable-next-line no-unused-vars
  // clearRouteCache({ commit }) {
  //   commit('clearObject');
  // },
  async loadRouteByPaginationForStore({ commit }, {
    storeGLN, startShift, endShift, tourName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/toursPagination`, {
        params: {
          storeGLN,
          startShift,
          endShift,
          tourName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_ROUTES_WITH_PAGINATION_STORE', response.data.items);
      // console.log('extended filiale', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ROUTES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async toursByStorePagination({ commit }, {
    storeGLN, startShift, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/toursByStorePagination`, {
        params: {
          storeGLN,
          startShift,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_ROUTE_BY_STORE_PAGINATION', response.data.items);
      // console.log('extended filiale', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ROUTES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async loadToursForTrucks({ commit }, {
    truckId, destinationStoreGln, startShift, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/ongoingToursForTruck`, {
        params: {
          truckId,
          destinationStoreGln,
          startShift,
        },
      })
      commit('SET_ROUTES_FOR_TRUCKS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async loadTrucksByTourId({ commit }, tourId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/tourTrucksByTourId`, {
        params: {
          tourId,
        },
      })
      commit('SET_TRUCKS_BY_TOURID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async tour_editable({ commit }, { currentTourId, newStartShift, newEndShift }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/tour_editable`, {
        params: {
          currentTourId, newStartShift, newEndShift,
        },
      })
      commit('SET_TOUR_EDITABLE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async busyTruckStuffByEmployeeUserIdStartShift({ commit }, { employeeUserId, startShift, endShift }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/busyTruckStuffByEmployeeUserIdStartShift`, {
        params: {
          employeeUserId,
          startShift,
          endShift,
        },
      })
      commit('SET_IS_EMPLOYEE_IN_TOUR', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetTrucks({ commit }) {
    commit('SET_TRUCKS_BY_TOURID', []);
  },
  async addTours({ commit }, {
    name, startTour, amount, endTour, storeGLN, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain}/addTour?name=${name}&startTour=${startTour}&amount=${amount}&endTour=${endTour}&storeGLN=${storeGLN}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      commit('SET_ACTIVEROUTE_BY_EMPLOYEEID', response.data)
      commit('SET_STATUS', true)
      successCallback()
      Swal.fire({
        icon: 'success',
        title: 'Route added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false)
      // Check the status code of the error response
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the tour.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addTruckToTour({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addTruckToTour`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Truck added to tour successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid truck data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the truck to the tour.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async getTourNameByEmployeeUserIdStartAndEndShift({ commit }, {
    employeeUserId, destinationStoreGLN, startShift, endShift,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/tourNameByEmployeeUserIdStartAndEndShift`, {
        params: {
          employeeUserId,
          destinationStoreGLN,
          startShift,
          endShift,
        },
      })
      commit('SET_ACTIVEROUTE_BY_EMPLOYEEID', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async potential_tours_by_employeePlanId({ commit }, {
    employeePlanningId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/potential_tours_by_employeePlanId`, {
        params: {
          employeePlanningId,
        },
      })
      commit('SET_POTENCIAL_TOURS_BY_EMPLOYEEPLANNID', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetTourss({ commit }) {
    commit('SET_ACTIVEROUTE_BY_EMPLOYEEID', [])
  },
  async releaseAndReplaceEmployeeFromTour({ commit }, { obj, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/releaseAndReplaceEmployeeFromTour`, obj);
      commit('SET_STATUS', true);
      successCallback();

      Swal.fire({
        icon: 'success',
        title: 'Employee released and replaced successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while releasing and replacing the employee.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async releaseEmployeeFromTour({ commit }, { obj, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/releaseEmployeeFromTour`, obj);
      commit('SET_STATUS', true);
      successCallback();

      Swal.fire({
        icon: 'success',
        title: 'Employee released from tour successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while releasing the employee from tour.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async editRoute({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editTour?tourId=${object.tourId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      await Swal.fire({
        icon: 'success',
        title: 'Tour updated successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      if (error.response && error.response.status === 400) {
        await Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'An error occurred while updating the tour.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadStoreManagementOverview({ commit }, {
    userId, startDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dailyOverview`, {
        params: {
          userId, startDate,
        },
      })
      commit('SET_STORE_MANAGEMENT_OVERVIEW', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async saturday_Working_Dates({ commit }, date) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/saturday_Working_Dates?date=${date}`)
      commit('SET_SATURDAY_WORKING_HOURS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async external_Working_Dates({ commit }, { managerId, date }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/external_Working_Dates?managerId=${managerId}&date=${date}`)
      commit('SET_EXTERNAL_WORKING_DAYS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async add_custom_TruckStuff({ commit }, {
    obj, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_custom_TruckStuff`, obj, {
        headers: { 'Content-Type': 'application/json' },
      })
      commit('SET_STATUS', true)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async move_planned_employee_to_another_store({ commit }, {
    obj, successCallback,
  }) {
    commit('SET_IS_LOADING', false)
    try {
      await axios.put(`${domain}/move_planned_employee_to_another_store`, obj, {
        headers: { 'Content-Type': 'application/json' },
      })
      commit('SET_STATUS', true)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true)
    successCallback()
  },
  async add_TruckStuff({ commit }, {
    obj, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_custom_TruckStuff`, obj, {
        headers: { 'Content-Type': 'application/json' },
      })
      commit('SET_STATUS', true)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async get_custom_TruckStuff({ commit }, { date, employeeUserId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/get_custom_TruckStuff?date=${date}&employeeUserId=${employeeUserId}`)
      commit('SET_CUSTOM_TRUCKSTYFFID', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetCustomTruckStffid({ commit }) {
    commit('SET_CUSTOM_TRUCKSTYFFID', [])
  },
  async add_Tours_Excel({ commit }, {
    obj, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addTours`, obj)
      commit('SET_STATUS', true)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async add_Tours_Metrics({ commit }, {
    obj, successCallback,
  }) {
    // commit('SET_IS_LOADING', false);
    // try {
    //   await axios.post(`${domain}/route_metrics`, obj)
    //   commit('SET_STATUS', true)
    // } catch (error) {
    //   commit('SET_IS_LOADING', true);
    // }
    // commit('SET_IS_LOADING', true);
    // successCallback()
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/route_metrics`, obj)
      commit('SET_STATUS', true)
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Routes added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the truck.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
