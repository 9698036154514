/* eslint-disable no-lonely-if */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader' || user.role == 'CoTeamLeader') {
          next('/store-management');
        }
      }
    },
  },
  {
    path: '/filiale',
    name: 'Filiale',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Filiale.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/employee-feedback',
    name: 'Employee Feedback',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/EmployeeFeedbacks.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/employee-payments',
    name: 'Employee Payments',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/EmployeePayments.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Tasks.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/exchangeTruck',
    name: 'Exchange Filiale',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/ExchangeTruck.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/exchangeEmployees',
    name: 'Exchange Employees',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/ExchangeEmployees.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/routes',
    name: 'Routes',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Routes.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/trucks',
    name: 'Trucks',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Trucks.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/equipments',
    name: 'Equipment',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('@/views/Equipments.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Users.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/leave-request',
    name: 'LeaveRequest',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/LeaveRequest.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        next();
      }
    },
  },
  {
    path: '/filialeCalculation',
    name: 'Filiale Calculation',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/FilialeCalculation.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/employeeCalculation',
    name: 'Employee Calculation',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/EmployeeCalculation.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/truckHistory',
    name: 'Truck History',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/TruckHistory.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Manager')
        ) {
          next();
        } else if (user.role == 'TeamLeader') {
          next('/');
        }
        // eslint-disable-next-line no-lonely-if
      }
    },
  },
  {
    path: '/saturday-working-hours',
    name: 'Saturday Working Hours',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/SaturdayWorkingHours.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        next();
      }
    },
  },
  {
    path: '/external-working-dayss',
    name: 'External Working Days',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/ExternalWorkingDays.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { hideNavigation: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(/* webpackChunkName: "about" */ '../views/About.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      let isLog = null;
      if (keyname) {
        isLog = keyname.authentication.isLoggedIn;
      }
      if (to.name == 'Login' && keyname != null && isLog) {
        next('/');
      } else {
        // eslint-disable-next-line no-lonely-if
        next();
      }
    },
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    meta: { hideNavigation: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue');
    },
    // beforeEnter: (to, from, next) => {
    //   const keyname = JSON.parse(localStorage.getItem('vuex'));
    //   const user = keyname?.authentication?.loggedInUser;

    //   if (!user) {
    //     // User is not logged in, redirect to resetPassword
    //     next('/resetPassword');
    //   } else {
    //     // User is logged in, check role and token
    //     if (user.token && (user.role === 'CEO' || user.role === 'Manager')) {
    //       next();
    //     } else if (user.role === 'TeamLeader') {
    //       next('/');
    //     }
    //   }
    // },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      let isLog = null;
      if (keyname) {
        isLog = keyname.authentication.isLoggedIn;
      }
      if (!isLog && to.path !== '/login' && to.path !== '/resetPassword') {
        next('/login');
      } else {
        next();
      }
    },


  },
  {
    path: '/store-management',
    name: 'Store Management',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/StoreManagement.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/store-management-overview',
    name: 'Store Management',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/StoreManagementOverview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/info',
    name: 'Info Details',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/info.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/personnel-planning',
    name: 'Personnel Planning',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/PersonnelPlanning.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/daily-overview',
    name: 'Daily Overview',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/DailyOverview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/CalendarOverview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/bank',
    name: 'BankHolidays',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/BankHolid.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
];


const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
