import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.userManagementDomain;
const domain2 = api.logisticStoreData;
const domain3 = api.tourManagement;

const state = {
  status: false,
  fullNamesByRoles: [],
  users: [],
  inactiveusers: [],
  fileteredActiveUsers: [],
  filteredInactiveUsers: [],
  teamLeaders: [],
  coTeamLeaders: [],
  currentPage: 1,
  totalItems: 0,
  currentPage_2: 1,
  totalItems_2: 0,
  totalItemsForExchangeEmployee: 0,
  currentPageExEmployee: 1,
  totalItemsInactive: 0,
  freeEmployees: [],
  exchangeEmployees: [],
  storeMainboardOverview: {},
  freeRegularEmployee: [],
  userInformation: {},
  photos: [],
  upComingVacations: [],
  regularEmployeeOverview: [],
  dailyReport: [],
  weeklyReport: [],
  forgetPassword: [],
  feedback: [],
  employeeFeedbackPagination: [],
  free_Employees_ForManagerByStoreId: [],
  payment: [],
  employeePaymentPagination: [],
  monthlyOverviewPaymentPagination: [],
  totalCurrentEstimatedEarnings: [],
  totalRoutesStats: [],
  mostEmployeesInRoute: [],
  dashboardFeedback: [],
  statsVacationRequests: [],
  dashboardDailyReporting: [],
};

const getters = {
  getUsers: (state) => {
    return state.users;
  },
  getPhotos: (state) => {
    return state.photos;
  },
  getUserInformation: (state) => {
    return state.userInformation;
  },
  getfreeEmployees: (state) => {
    return state.freeEmployees;
  },
  getfreeTeamLeaders: (state) => {
    return state.teamLeaders;
  },
  getfreeCoTeamLeaders: (state) => {
    return state.coTeamLeaders;
  },

  getInactiveUsers: (state) => {
    return state.inactiveusers;
  },

  getCurrentPageForUserss: (state) => {
    return state.currentPage;
  },
  getCurrentPageForUserss_2: (state) => {
    return state.currentPage_2;
  },

  getFilteredActiveUsers: (state) => {
    return state.fileteredActiveUsers;
  },
  getFilteredInactiveUsers: (state) => {
    return state.filteredInactiveUsers;
  },

  getTotalItemsForUsers: (state) => {
    return state.totalItems;
  },
  getTotalItemsForUsers_2: (state) => {
    return state.totalItems_2;
  },
  getTotalItemsForInActiveUsers: (state) => {
    return state.totalItemsInactive;
  },
  fullNamesByRoles: (state) => {
    return state.fullNamesByRoles;
  },
  getExchangeEmployee: (state) => {
    return state.exchangeEmployees
  },
  getFreeRegularEmployee: (state) => {
    return state.freeRegularEmployee
  },
  getTotalItemsForEXUsers: (state) => {
    return state.totalItemsForExchangeEmployee;
  },
  getCurrentPageForEXUserss: (state) => {
    return state.cancelEXEmployee;
  },
  // Dashboard
  getStoreMainboardOverview: (state) => {
    return state.storeMainboardOverview
  },
  getUpComingVacations: (state) => {
    return state.upComingVacations
  },
  getDailyReport: (state) => {
    return state.dailyReport
  },
  getWeeklyReport: (state) => {
    return state.weeklyReport
  },
  // calculation
  getRegularEmployeesOverview: (state) => {
    return state.regularEmployeeOverview
  },
  // Forgot Password
  getForgotPassword: (state) => {
    return state.forgetPassword
  },
  getFeedback: (state) => {
    return state.feedback
  },
  getEmployeeFeedbackPagination: (state) => {
    return state.employeeFeedbackPagination
  },
  getFreeEmployeesForManagerByStoreId: (state) => {
    return state.free_Employees_ForManagerByStoreId
  },
  getPayment: (state) => {
    return state.payment
  },
  getEmployeePaymentPagination: (state) => {
    return state.employeePaymentPagination
  },
  getMonthlyOverviewPaymentPagination: (state) => {
    return state.monthlyOverviewPaymentPagination
  },
  getTotalCurrentEstimatedEarnings: (state) => {
    return state.totalCurrentEstimatedEarnings
  },
  getTotalRoutesStats: (state) => {
    return state.totalRoutesStats
  },
  getMostEmployeesInRoute: (state) => {
    return state.mostEmployeesInRoute
  },
  getDashboardFeedback: (state) => {
    return state.dashboardFeedback
  },
  getStatsVacationRequests: (state) => {
    return state.statsVacationRequests
  },
  getDashboardDailyReporting: (state) => {
    return state.dashboardDailyReporting
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_CURRENTPAGE_2(state, payload) {
    state.currentPage_2 = payload;
  },
  SET_USERS(state, latest) {
    state.users = latest;
  },
  SET_USER_INFORMATION(state, latest) {
    state.userInformation = latest;
  },
  SET_INACTIVEUSERS(state, latest) {
    state.inactiveusers = latest;
  },
  SET_FILTERED_ACTIVE_USERS(state, latest) {
    state.fileteredActiveUsers = latest;
  },
  SET_FILTERED_INACTIVEUSERS(state, latest) {
    state.filteredInactiveUsers = latest;
  },
  SET_TOTALITEMS_USERS(state, latest) {
    state.totalItems = latest;
  },
  SET_TOTALITEMS_USERS_2(state, latest) {
    state.totalItems_2 = latest;
  },
  SET_TOTALITEMS_INACTIVEUSERS(state, latest) {
    state.totalItemsInactive = latest;
  },
  SET_FULL_NAMES_BY_ROLES(state, data) {
    state.fullNamesByRoles = data;
  },
  SET_FREE_TEAMLEADERS(state, data) {
    state.teamLeaders = data;
  },
  SET_FREE_COTEAMLEADERS(state, data) {
    state.coTeamLeaders = data;
  },
  SET_FREE_EMPLOYEE(state, data) {
    state.freeEmployees = data;
  },
  REMOVE_FREE_EMPLOYEE(state, user) {
    state.freeEmployees = state.freeEmployees.filter((u) => { return u.userId !== user.userId });
  },
  // Exchange Employee
  SET_EXCHANGE_EMPLOYEE(state, data) {
    state.exchangeEmployees = data
  },
  SET_FREE_REGULAR_EMPLOYEE(state, data) {
    state.freeRegularEmployee = data
  },
  SET_TOTALITEMS_EX_USERS(state, payload) {
    state.totalItemsForExchangeEmployee = payload;
  },
  SET_CURRENTPAGE_EX_USERS(state, payload) {
    state.currentPageExEmployee = payload;
  },
  // Dashboard
  SET_STORE_MAINBOARD_OVERVIEW(state, data) {
    state.storeMainboardOverview = data
  },
  SET_PHOTOS(state, data) {
    state.photos = data
  },
  SET_UPCOMING_VACATIONS(state, data) {
    state.upComingVacations = data
  },
  SET_DAILY_REPORT(state, data) {
    state.dailyReport = data
  },
  SET_WEEKLY_REPORT(state, data) {
    state.weeklyReport = data
  },
  // calculations
  SET_REGULAR_EMPLOYEE(state, data) {
    state.regularEmployeeOverview = data
  },
  // ForgetPassword
  SET_FORGOT_PASSWORD(state, data) {
    state.forgetPassword = data
  },
  SET_FEEDBACK(state, data) {
    state.feedback = data
  },
  SET_EMPLOYEE_FEEDBACK_PAGINATION(state, data) {
    state.employeeFeedbackPagination = data
  },
  SET_FREE_EMPLOYEES_FOR_MANAGER_BY_STOREID(state, data) {
    state.free_Employees_ForManagerByStoreId = data
  },
  SET_PAYMENT(state, data) {
    state.payment = data
  },
  SET_EMPLOYEE_PAYMENT_PAGINATION(state, data) {
    state.employeePaymentPagination = data
  },
  SET_MONTHLY_OVERVIEW_PAYMENT_PAGINATION(state, data) {
    state.monthlyOverviewPaymentPagination = data
  },
  SET_TOTAL_CURRENT_ESTIMATED_EARNINGS(state, data) {
    state.totalCurrentEstimatedEarnings = data
  },
  SET_TOTAL_ROUTES_STATS(state, data) {
    state.totalRoutesStats = data
  },
  SET_MOST_EMPLOYEES_IN_ROUTE(state, data) {
    state.mostEmployeesInRoute = data
  },
  SET_DASHBOARD_FEEDBACK(state, data) {
    state.dashboardFeedback = data
  },
  SET_STATS_VACATION_REQUESTS(state, data) {
    state.statsVacationRequests = data
  },
  SET_DASHBOARD_DAILY_REPORTING(state, data) {
    state.dashboardDailyReporting = data
  },
};
const actions = {
  async loadDashboardDailyReporting({ commit },
    { startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/dashboard_dailyReportingForStoreByBudget?StartDate=${startDate}&EndDate=${endDate}`);
      commit('SET_DASHBOARD_DAILY_REPORTING', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadstatsVacationRequests({ commit },
    { startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/stats_vacationRequests?StartDate=${startDate}&EndDate=${endDate}`);
      commit('SET_STATS_VACATION_REQUESTS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDashboardFeedback({ commit },
    { startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dashboard_feedback?StartDate=${startDate}&EndDate=${endDate}`);
      commit('SET_DASHBOARD_FEEDBACK', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMostEmployeesInRoute({ commit },
    { StartDate, EndDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/most_employeesInRoute?StartDate=${StartDate}&EndDate=${EndDate}`);
      commit('SET_MOST_EMPLOYEES_IN_ROUTE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadTotalCurrentEstimatedEarnings({ commit },
    { StartDate, EndDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/total_current_estimated_earnings?StartDate=${StartDate}&EndDate=${EndDate}`);
      commit('SET_TOTAL_CURRENT_ESTIMATED_EARNINGS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadTotalRoutesStats({ commit },
    { StartDate, EndDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/total_routes_stats?StartDate=${StartDate}&EndDate=${EndDate}`);
      commit('SET_TOTAL_ROUTES_STATS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getPhotosByDocumentId({ commit },
    documentId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getPhotosByDocumentId`, {
        params: {
          documentId,
        },
      });
      commit('SET_PHOTOS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getUsersPagination({ commit }, {
    status, pageNumber, pageSize, firstName, lastName,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/usersByStatusPagination`, {
        params: {
          status,
          pageNumber,
          pageSize,
          firstName,
          lastName,
        },
      });
      commit('SET_USERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadEmployeeFeedbackPagination({ commit }, {
    firstName, lastName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employeeFeedbackPagination`, {
        params: {
          firstName, lastName, pageNumber, pageSize,
        },
      });
      commit('SET_EMPLOYEE_FEEDBACK_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMonthlyOverviewPaymentPagination({ commit }, {
    year, month, employeeType, filterPaidEmployees, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/monthlyOverviewPaymentPagination`, {
        params: {
          year, month, employeeType, filterPaidEmployees, pageNumber, pageSize,
        },
      });
      commit('SET_MONTHLY_OVERVIEW_PAYMENT_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE_2', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS_2', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadEmployeePaymentPagination({ commit }, {
    firstName, lastName, employeeType, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employeePaymentPagination`, {
        params: {
          firstName, lastName, employeeType, pageNumber, pageSize,
        },
      });
      commit('SET_EMPLOYEE_PAYMENT_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFeedback({ commit }, {
    userId, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/feedback`, {
        params: {
          userId, year,
        },
      });
      commit('SET_FEEDBACK', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadPayments({ commit }, {
    userId, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/payment`, {
        params: {
          userId, year,
        },
      });
      commit('SET_PAYMENT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getUserInformationById({ commit }, id) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/fullUserById?userId=${id}`);
      commit('SET_USER_INFORMATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getInactiveUsersPagination({ commit }, {
    status, pageNumber, pageSize, firstName, lastName,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/usersByStatusPagination`, {
        params: {
          status,
          pageNumber,
          pageSize,
          firstName,
          lastName,
        },
      });
      commit('SET_INACTIVEUSERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_INACTIVEUSERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async addUser({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addUser`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async add_feedback({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_feedback`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User`s feedback added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async add_payment({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_payment`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User`s payment added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addTruckStuff({ commit }, { tourTruckId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const updatedRequestBody = {
        ...object,
        truckStaffReponsibilities: object.truckStaffReponsibilities.map((staff) => {
          return {
            ...staff,
            employeeUserId: staff.employeeUserId.userId,
          }
        }),
      }

      await axios.post(`${domain3}/addTruckStuff?tourTruckId=${tourTruckId}`, updatedRequestBody, {
        headers: { 'Content-Type': 'application/json' },
      });

      commit('SET_STATUS', true);
      successCallback();

      Swal.fire({
        icon: 'success',
        title: 'Truck staff added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid truck staff data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the truck staff.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deactivateUserById({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/deactivateUserById`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User deactivated successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deactivating the user.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async edit_feedback({ commit }, { feedbackId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_feedback?feedbackId=${feedbackId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User`feedback is edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deactivating the user.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async edit_payment({ commit }, { paymentId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_payment?paymentId=${paymentId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User`payment is edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deactivating the user.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async activateUserById({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/activateUserById?userId=${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User activated successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while activating the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editUserById({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editFullUserById?userId=${object.editUserRequestDto.userId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while editing the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async filterActiveUsersByName({ commit }, name) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/activeUsersByFullNameLike?fullName=${name}`);
      commit('SET_FILTERED_ACTIVE_USERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredActiveUsers({ commit }) {
    commit('SET_FILTERED_ACTIVE_USERS', []);
  },
  async filterInactiveUsersByName({ commit }, name) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inactiveUsersByFullNameLike?fullName=${name}`);
      commit('SET_FILTERED_INACTIVEUSERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredInactiveUsers({ commit }) {
    commit('SET_FILTERED_INACTIVEUSERS', []);
  },
  async loadUsersFullNamesById({ commit }, name) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/userFullNamesAndIdsByRole?role=${name}`);
      commit('SET_FULL_NAMES_BY_ROLES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async freeTeamLeaders({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/freeTeamLeaders`);
      commit('SET_FREE_TEAMLEADERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async freeCoTeamLeaders({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/freeCoTeamLeaders`);
      commit('SET_FREE_COTEAMLEADERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async freeEmployee({ commit }, {
    storeId, startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/free_planned_employees`, {
        params: {
          storeId,
          startDate,
          endDate,
        },
      });
      commit('SET_FREE_EMPLOYEE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async freeEmployeesForManagerByStoreId({ commit }, {
    storeId, startShift, endShift,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/freeEmployeesForManagerByStoreId`, {
        params: {
          storeId,
          startShift,
          endShift,
        },
      });
      commit('SET_FREE_EMPLOYEES_FOR_MANAGER_BY_STOREID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async assignEmployeeToStoree({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/assignEmployeeToStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Employee assigned to store successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid employee data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning the employee to the store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  // Exchange employee
  async getExchangeEmployeePagination({ commit }, {
    fullName, sourceStoreId, destinationStoreId, startShift, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/exchangeEmployeesPagination`, {
        params: {
          fullName,
          sourceStoreId,
          destinationStoreId,
          startShift,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_EXCHANGE_EMPLOYEE', response.data.items);
      commit('SET_CURRENTPAGE_EX_USERS', response.data.currentPage);
      commit('SET_TOTALITEMS_EX_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFreeRegularEmployee({ commit }, {
    storeId, startShift, endShift, includeTeamLeaders, includeManager,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/freeRegularEmplyoees`, {
        params: {
          storeId,
          startShift,
          endShift,
          includeTeamLeaders,
          includeManager,
        },
      });
      commit('SET_FREE_REGULAR_EMPLOYEE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async assignExhangeEmployeesToStore({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/assignExhangeEmployeesToStore`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Employees assigned to store successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while assigning employees to store',
        text: error.message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async cancelEXEmployee({ commit }, { exchangeEmployee, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/cancelExchangeEmployee?exchangeEmployee=${exchangeEmployee}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Exchange employee cancelled successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while cancelling the exchange employee.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async deleteImage({ commit }, { Id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/deletePhotoById`, {
        params: {
          Id,
        },
      });
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  // DASHBOARD
  async loadStoreMainboardOverview({ commit }, {
    storeGLN, startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/storeMainboardOverviewResponseDto`, {
        params: {
          storeGLN,
          startDate,
          endDate,

        },
      })
      commit('SET_STORE_MAINBOARD_OVERVIEW', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadUpComingVacations({ commit }, {
    storeGLN, numberOfDays, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/upcomingVacationsForStore`, {
        params: {
          storeGLN,
          numberOfDays,

        },
      })
      commit('SET_UPCOMING_VACATIONS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async loadDailyReportForStore({ commit }, {
    startDate, endDate, calculateTotal,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/dailyReportingForStore?startDate=${startDate}&endDate=${endDate}&calculateTotal=${calculateTotal}`)
      commit('SET_DAILY_REPORT', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadWeeklyReportForStore({ commit }, {
    startDate, endDate, calculateTotal,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/weeklyReportingForStore?startDate=${startDate}&endDate=${endDate}&calculateTotal=${calculateTotal}`)
      commit('SET_WEEKLY_REPORT', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  // calculations
  async loadRegularEmployeeOverview({ commit }, {
    storeId, status, isRegular,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/regularEmployeeOverview?storeId=${storeId}&status=${status}&isRegular=${isRegular}`)
      commit('SET_REGULAR_EMPLOYEE', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  // logOut
  async loadForgetPassword({ commit }, { email }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/forgot-password?email=${email}`);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Email sent successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid email. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while sending the email.Try again later',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_FORGOT_PASSWORD', true);
    commit('SET_IS_LOADING', true);
  },

  async loadResetPassword({ commit }, { token, newPassword }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/reset-password?token=${token}&newPassword=${newPassword}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async loadLogOut({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/logout`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    window.localStorage.clear();
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
