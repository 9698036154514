<template>
  <b-modal
    id="vacation-modal"
    v-model="showModal"
    hide-footer
    hide-header
    no-close-on-backdrop
    size="lg"
    title="Login"
    class="modaliVacation"
    @close="hideModal"
  >
    <b-form>
      <!-- login form fields here -->
      <p style="margin-bottom: 50px; margin-top: 10px;">Welcome back,<b style="color: black;"> {{ getLoggedInUser.firstName }} {{ getLoggedInUser.lastName }}! </b>While you were away, <b style="color: black;">{{ getLoggedInUser.coManager }}</b>
        has been assigned as the manager of your stores. <br> Do you want to remove their leadership status now?</p>
      <b-button
        type="submit"
        variant="primary"
        class="buttonSubmit"
        @click.prevent="hideModal(), setOnVacation()"
      >
        No, just watching
      </b-button>
      <b-button
        type="submit"
        variant="primary"
        class="buttonCancel"
        @click.prevent="hideModal(), takeLeadership() "
      >
        Yes, I'm back from leave request
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LoginModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getIsOnVacation']),
  },
  methods: {
    ...mapActions(['removeCoManagerFromStores', 'updateIsOnVacation']),
    hideModal() {
      this.$emit('hideModal');
      this.updateIsOnVacation(false);
    },
    takeLeadership() {
      this.removeCoManagerFromStores();
      this.updateIsOnVacation(false);
    },
  },
}
</script>
<style lang="scss">
.modaliVacation{
  margin-top: 500px !important;
}
</style>
