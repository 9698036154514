var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"side"}},[(_vm.getIsSidebarHidden == false)?_c('section',{staticClass:"sidebar",class:{
        hide: _vm.getIsSidebarHidden == true,
        show: _vm.getIsSidebarHidden == false,
      },staticStyle:{"margin-top":"0rem","box-shadow":"0px 3px 5px #e3e3e3","padding-bottom":"10%"}},[_c('div',{staticStyle:{"height":"60px","padding":"0.4rem 1.3rem","border-bottom":"1px solid lightblue"},attrs:{"button":""}},[_c('router-link',{staticStyle:{"background-color":"#0071AE !important","padding-bottom":"15px !important"},attrs:{"to":"/"}},[_c('img',{staticClass:"image1",attrs:{"src":require("@/assets/DSIIII.png"),"width":"40px","alt":"test"}}),_c('img',{staticClass:"image2",attrs:{"src":require("@/assets/DSSSS.svg"),"width":"150px","alt":"test"}})])],1),_c('b-list-group',[(
            _vm.getLoggedInUser.role == 'Manager' ||
              _vm.getLoggedInUser.role == 'CEO')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('img',{staticStyle:{"width":"40px","height":"60px","margin-right":"5px"},attrs:{"src":require("./../assets/images/dashboardd.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Dashboard")))])])],1):_vm._e(),(
            _vm.getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'CoTeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
              _vm.getLoggedInUser.role == 'CEO'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-3",modifiers:{"collapse-3":true}}],attrs:{"button":""}},[_c('router-link',{staticClass:"disabled-link",attrs:{"to":"/nowhere"}},[_c('img',{staticStyle:{"width":"40px","height":"60px","margin-right":"5px"},attrs:{"src":require("./../assets/images/RouteManagement.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t("Store"))+" "),_c('br'),_vm._v(_vm._s(_vm.$t("Management")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-3","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role == 'TeamLeader' ||
                _vm.getLoggedInUser.role == 'CoTeamLeader' ||
                _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/store-management"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("RouteManagement"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/store-management-overview"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("FilialeOverview"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/daily-overview"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("BudgetPreperation"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/personnel-planning"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("PersonnelPlanning"))+" ")])])],1):_vm._e(),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/tasks"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Tasks"))+" ")])])],1)],1),(
            // getLoggedInUser.role == 'TeamLeader' ||
            _vm.getLoggedInUser.role == 'Manager' ||
              _vm.getLoggedInUser.role == 'CEO'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],attrs:{"button":""}},[_c('router-link',{staticClass:"disabled-link",attrs:{"to":"/nowhere"}},[_c('img',{staticStyle:{"width":"40px","height":"60px","margin-right":"5px"},attrs:{"src":require("./../assets/images/Property.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t("Properties"))+" ")])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-2","role":"tabpanel","accordion":"my-accordion"}},[(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/filiale"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Filiales"))+" ")])])],1):_vm._e(),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/routes"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Routes"))+" ")])])],1),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/trucks"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Trucks"))+" ")])])],1)],1),(
            // getLoggedInUser.role == 'TeamLeader' ||
            _vm.getLoggedInUser.role == 'Manager' ||
              _vm.getLoggedInUser.role == 'CEO'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-5",modifiers:{"collapse-5":true}}],attrs:{"button":""}},[_c('router-link',{staticClass:"disabled-link",attrs:{"to":"/nowhere"}},[_c('img',{staticStyle:{"width":"40px","height":"60px","margin-right":"5px"},attrs:{"src":require("./../assets/images/Exchangee.svg"),"alt":""}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("ExchangeProperties"))+" ")])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-5","role":"tabpanel","accordion":"my-accordion"}},[(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/exchangeTruck"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ExchangeTrucks"))+" ")])])],1):_vm._e(),(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/exchangeEmployees"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ExchangeEmployees"))+" ")])])],1):_vm._e()],1),(
            // getLoggedInUser.role == 'TeamLeader' ||
            _vm.getLoggedInUser.role == 'Manager' ||
              _vm.getLoggedInUser.role == 'CEO'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-4",modifiers:{"collapse-4":true}}],attrs:{"button":""}},[_c('router-link',{staticClass:"disabled-link",attrs:{"to":"/nowhere"}},[_c('img',{staticStyle:{"width":"40px","height":"60px","margin-right":"5px"},attrs:{"src":require("./../assets/images/employee.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Employees")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-4","accordion":"my-accordion"}},[(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/users"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("EmployeeManagement"))+" ")])])],1):_vm._e(),(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/leave-request"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("VacationManagement"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'TeamLeader' ||
                _vm.getLoggedInUser.role == 'CoTeamLeader' ||
                _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/calendar"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("VacationPlan")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'TeamLeader' ||
                _vm.getLoggedInUser.role == 'CoTeamLeader' ||
                _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/employee-feedback"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("Feedback")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'TeamLeader' ||
                _vm.getLoggedInUser.role == 'CoTeamLeader' ||
                _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/employee-payments"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("Payment")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'TeamLeader' ||
                _vm.getLoggedInUser.role == 'CoTeamLeader' ||
                _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO' ||
                _vm.getLoggedInUser.role == 'Admin'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/bank"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("BankHolidays")))])])],1):_vm._e()],1),(
            // getLoggedInUser.role == 'TeamLeader' ||
            _vm.getLoggedInUser.role == 'Manager' ||
              _vm.getLoggedInUser.role == 'CEO'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-6",modifiers:{"collapse-6":true}}],attrs:{"button":""}},[_c('router-link',{staticClass:"disabled-link",attrs:{"to":"/nowhere"}},[_c('img',{staticStyle:{"width":"40px","height":"60px","margin-right":"5px"},attrs:{"src":require("./../assets/images/calculation.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t("Calculations")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-6","accordion":"my-accordion"}},[(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/filialeCalculation"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("CalculationFiliales"))+" ")])])],1):_vm._e(),(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/EmployeeCalculation"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("CalculationEmployees"))+" ")])])],1):_vm._e(),(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/saturday-working-hours"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("SaturdayWorkingDays"))+" ")])])],1):_vm._e(),(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/external-working-dayss"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ExternalWorkingDays"))+" ")])])],1):_vm._e(),(
              // getLoggedInUser.role == 'TeamLeader' ||
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/truckHistory"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("TruckHistory"))+" ")])])],1):_vm._e()],1),_c('b-list-group-item',[_c('router-link',{attrs:{"to":"/info"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('img',{staticStyle:{"width":"40px","height":"60px","margin-right":"5px"},attrs:{"src":require("./../assets/images/info.svg"),"alt":"test"}}),_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Info"))+" ")])])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }