import Vue from 'vue';
import {
  BootstrapVue, ToastPlugin, ModalPlugin, IconsPlugin,
} from 'bootstrap-vue';
import axios from 'axios';





import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select';
// import { createI18n } from 'vue-i18n'
import Notifications from 'vue-notification';
import VueI18n from 'vue-i18n';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import App from './App.vue';
import router from './router';
import store from './store';
// import signalr from './microsoft-signalR/signalR';
import en from './i18n/en.json';
import sq from './i18n/sq.json';
import de from './i18n/de.json';
import fr from './i18n/fr.json';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-good-table/dist/vue-good-table.css';
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import connection from './microsoft-signalR/signalR';



library.add(faArrowRightFromBracket);

axios.interceptors.request.use((config) => {
  // in case of calling refresh-cookies method please uncomment the below line
  // config.withCredentials = true;
  // eslint-disable-next-line prefer-const
  let _token = store.state.authentication.loggedInUser.token;
  if (_token) config.headers.Authorization = `Bearer ${_token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  // eslint-disable-next-line arrow-body-style
  (error) => Promise.reject(error),
);

// eslint-disable-next-line no-unused-vars
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.state.authentication.language,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {
    en,
    sq,
    de,
    fr,
  },
});
Vue.use(BootstrapVue);
Vue.use(Notifications)
Vue.use(VueSweetalert2);

// Vue.use(Notifications)
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(BootstrapVue, {
  BDropdown: {
    popperOpts: {
      positionFixed: true,
    },
    boundary: 'window',
  },
  BFormInput: {
    autocomplete: 'off',
  },
  BInput: {
    autocomplete: 'off',
  },
});

// import the styles

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
Vue.component('vue-select', vSelect);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// import assets styles
// eslint-disable-next-line import/no-unresolved
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  connection,
  render: (h) => {
    return h(App);
  },
}).$mount('#app');
