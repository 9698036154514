<template>
  <div class="home">
    <!-- <Navbar />
    <Sidebar /> -->
    <Main
      :vacation-time="propNumber"
      @click="obj"
      @show-other-component="showOtherComponent = true"
      @show-the-component="showOtherComponent = false"
    />

    <!-- <FilialeDetails
      v-if="!showOtherComponent"
      :store-gln="storeGLN"
      @time="emitNumber"
    /> -->
    <OnVacationModal
      v-if="getLoggedInUser.isOnVacation == true"
      :show-modal="showLoginModal"
      @hideModal="hideM"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import Main from '@/components/dashboard/Main.vue'
// import FilialeDetails from '@/components/FilialeDetails.vue'
import OnVacationModal from '@/components/OnVacationModal.vue'

export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    Main,
    // FilialeDetails,
    OnVacationModal,
  },
  data() {
    return {
      storeGLN: '',
      showOtherComponent: false,
      showLoginModal: true,
      propNumber: null,
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getIsOnVacation', 'getIsLoading']),
  },
  methods: {
    obj(value) {
      this.storeGLN = value
    },
    emitNumber(value) {
      // console.log('emitValue', value)
      this.propNumber = value
    },
    showLogin() {
      this.showLoginModal = true
    },
    hideM() {
      this.showLoginModal = false
    },
  },
}
</script>
