<template>
  <div>
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Bar } from 'vue-chartjs/legacy';
// import moment from 'moment';
import {
  Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  computed: {
    ...mapGetters(['getFiliales', 'getDashboardDailyReporting']),
    filteredDashboardDailyReporting() {
      const dashboardData = this.getDashboardDailyReporting || [];
      const filteredData = dashboardData.filter((item) => { return item.storeName !== null });
      return filteredData;
    },
  },
  watch: {
    getDashboardDailyReporting: {
      immediate: true,
      handler() {
        if (this.getDashboardDailyReporting) {
          this.updateChartData();
        }
      },
    },
  },
  methods: {
    ...mapActions(['dailyReportingForStoreByBudget']),
    updateChartData() {
      this.chartData.labels = this.filteredDashboardDailyReporting.map((filiale) => { return filiale.storeName });
      this.chartData.datasets = [
        {
          label: `${this.$t('EstimatedEarnings')}`,
          backgroundColor: ['#55B94A'],
          data: this.filteredDashboardDailyReporting.map((filiale) => { return filiale.estimate }),
        },
        {
          label: `${this.$t('CurrentEarnings')}`,
          backgroundColor: ['#1970AB'],
          data: this.filteredDashboardDailyReporting.map((filiale) => { return filiale.current }),
        },
      ];
    },
  },
};
</script>

<style scoped>
.container-bar-chart {
  padding: 10px 20px 0px 20px;
  width: 100%;
  border-radius: 9.415px;
/* border: 2px solid rgba(217, 217, 217, 0.26); */

background: #FFF;
}
</style>
