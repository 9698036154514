
const AWSurl = {};
const api = {};
if (process.env.VUE_APP_UserManagementDomain == null) {
  api.userManagementDomain = 'https://logisticauthentication.digitwebsite.ch/api/v1';
  api.logisticStoreData = 'https://logisticstoredata.digitwebsite.ch/api/v1';
  api.tourManagement = 'https://logistictourmanagement.digitwebsite.ch/api/v1';
  api.tourManagementForNotification = 'https://logistictourmanagement.digitwebsite.ch';
  api.vacation = 'https://vacationfashion.digitwebsite.ch/api/v1/DsLogistik';

  api.countryId = '9080b1da-4da4-4b25-5c93-08db31f6edb2';


  AWSurl.bucketName = 'spim-file-development';
  AWSurl.accessKeyId = 'AKIAZN2PMAGNIXQ74O4R';
  AWSurl.secretAccessKey = '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';
  AWSurl.region = 'eu-central-1';
} else {
  api.userManagementDomain = process.env.VUE_APP_UserManagementDomain;
  api.logisticStoreData = process.env.VUE_APP_StoreDataDomain;
  api.tourManagement = process.env.VUE_APP_TourManagementDomain;
  api.tourManagementForNotification = process.env.VUE_APP_TourManagementForNotificationDomain;
  api.vacation = process.env.VUE_APP_VacationDomain;
  api.countryId = process.env.VUE_APP_countryId;



  AWSurl.bucketName = process.env.VUE_APP_bucketName;
  AWSurl.accessKeyId = process.env.VUE_APP_accessKeyId;
  AWSurl.secretAccessKey = process.env.VUE_APP_secretAccessKey;
  AWSurl.region = process.env.VUE_APP_region;
}

module.exports = {
  api,
  AWSurl,
};
