import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.logisticStoreData;
const domain1 = api.tourManagement;

const state = {
  status: false,
  fullNamesByRoles: [],
  users: [],
  inactiveusers: [],
  fileteredActiveUsers: [],
  filteredInactiveUsers: [],
  currentPage: 1,
  totalItems: 0,
  currentPageExTrucks: 1,
  totalItemsExTrucks: 0,
  totalItemsInactive: 0,
  paginatedTrucks: [],
  paginatedTrucksForDeactive: [],
  storeTrucks: [],
  filterPlateTruck: [],
  filterPlateExchangeTruck: [],
  freeTrucks: [],
  tourStaff: [],
  exchangeTruck: [],
  freeRegularTrucksByStore: [],
  freePrivateUsers: [],
  isBusy: null,
  truckHistory: [],
};

const getters = {
  getCurrentPageForUsers: (state) => {
    return state.currentPage;
  },
  getTrucksPagination: (state) => {
    return state.paginatedTrucks;
  },
  getTrucksPaginationforDeactive: (state) => {
    return state.paginatedTrucksForDeactive;
  },
  getTrucksByStore: (state) => {
    return state.storeTrucks;
  },
  getFilterPlateTruck: (state) => {
    return state.filterPlateTruck;
  },
  getTotalItemsForTrucks: (state) => {
    return state.totalItems;
  },
  getFreeTrucks: (state) => {
    return state.freeTrucks
  },
  getStaffByTourTruckId: (state) => {
    return state.tourStaff
  },
  getIsTruckBusy: (state) => {
    return state.isBusy
  },
  // ExchangeTrucks
  getExchangeTrucks: (state) => {
    return state.exchangeTruck
  },
  getFreeRegularTrucksByStore: (state) => {
    return state.freeRegularTrucksByStore
  },
  getFreePrivateUsers: (state) => {
    return state.freePrivateUsers
  },
  getFilterPlateExchangeTruck: (state) => {
    return state.filterPlateExchangeTruck;
  },
  getCurrentPageForEXUsers: (state) => {
    return state.currentPageExTrucks;
  },
  getTotalItemsForEXTrucks: (state) => {
    return state.totalItemsExTrucks;
  },
  getTruckHistory: (state) => {
    return state.truckHistory
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  // SET_USERS(state, latest) {
  //   state.users = latest;
  // },
  // SET_INACTIVEUSERS(state, latest) {
  //   state.inactiveusers = latest;
  // },
  SET_FILTERED_ACTIVE_USERS(state, latest) {
    state.fileteredActiveUsers = latest;
  },
  SET_FILTERED_INACTIVEUSERS(state, latest) {
    state.filteredInactiveUsers = latest;
  },
  SET_TOTALITEMS_TRUCKS(state, latest) {
    state.totalItems = latest;
  },
  SET_TOTALITEMS_INACTIVEUSERS(state, latest) {
    state.totalItemsInactive = latest;
  },
  SET_FULL_NAMES_BY_ROLES(state, data) {
    state.fullNamesByRoles = data;
  },
  SET_TRUCK_BY_PAGINATION(state, latest) {
    state.paginatedTrucks = latest;
  },
  SET_TRUCK_BY_PAGINATION_DEACTIVE(state, latest) {
    state.paginatedTrucksForDeactive = latest;
  },
  SET_TRUCK_BY_STORE(state, latest) {
    state.storeTrucks = latest;
  },
  SET_FILTERED_PLATE_TRUCK(state, latest) {
    state.filterPlateTruck = latest;
  },
  SET_FREE_TRUCKS(state, latest) {
    state.freeTrucks = latest
  },
  SET_STAFF_BY_TOURTRUCKID(state, latest) {
    state.tourStaff = latest
  },
  SET_IS_TRUCK_BUSY(state, latest) {
    state.isBusy = latest
  },
  // Exchange Trucks
  SET_EXCHANGE_TRUCK_BY_PAGINATION(state, latest) {
    state.exchangeTruck = latest
  },
  SET_FREE_REGULAR_TRUCKS_BY_STORE(state, latest) {
    state.freeRegularTrucksByStore = latest
  },
  SET_PRIVATE_USERS(state, latest) {
    state.freePrivateUsers = latest
  },
  SET_FILTERED_PLATE_EXCHANGE_TRUCK(state, latest) {
    state.filterPlateExchangeTruck = latest;
  },
  SET_CURRENTPAGE_EX_TRUCKS(state, payload) {
    state.currentPageExTrucks = payload;
  },
  SET_TOTALITEMS_TRUCKS_EX_TRUCKS(state, latest) {
    state.totalItemsExTrucks = latest;
  },
  SET_TRUCK_HISTORY(state, latest) {
    state.truckHistory = latest
  },
};
const actions = {
  async loadTrucksPaginationWithoutStore({ commit }, {
    storeId, registrationPlate, status, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/trucksByStatusPagination`, {
        params: {
          storeId,
          registrationPlate,
          status,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TRUCK_BY_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_TRUCKS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadTrucksPaginationForDeactive({ commit }, {
    storeId, registrationPlate, status, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/trucksByStatusPagination`, {
        params: {
          storeId,
          registrationPlate,
          status,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TRUCK_BY_PAGINATION_DEACTIVE', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_TRUCKS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async addTruck({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addTruck`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Truck added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the truck.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilteredPlateTruck({ commit }, { filter, registrationPlate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/trucksByRegistrationPlateLike`, {
        params: {
          filter,
          registrationPlate,
        },
      });
      commit('SET_FILTERED_PLATE_TRUCK', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredPlate({ commit }) {
    commit('SET_FILTERED_PLATE_TRUCK', []);
  },
  async editTruckById({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editTruck/${object.truckId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Truck updated successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      // Check the status code of the error response
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while updating the truck.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async exchangeTruckInATour({ commit }, {
    tourId, truckId, newTruckId, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain1}/editTourTruckByTourAndTruckId`, {
        tourId,
        truckId,
        newTruckId,
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Truck exchange successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while exchanging the truck.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async deactivateAndActivateTruck({ commit }, { truckId, status, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/activateOrDeactivateTruck/${truckId}?status=${status}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Truck status updated successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while updating the truck status.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },

  async loadFreeTrucks({ commit }, {
    storeGLN, startShift, endShift, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/freeTrucksByStoreGLN`, {
        params: {
          storeGLN,
          startShift,
          endShift,
        },
      });
      commit('SET_FREE_TRUCKS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async assignTruckStore({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/assignTruckToStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();

      Swal.fire({
        icon: 'success',
        title: 'Truck assigned to store successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);

      // Check the status code of the error response
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning the truck to store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async truckStaffByTourTruckId({ commit },
    tourTruckId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain1}/truckStaffByTourTruckId`, {
        params: {
          tourTruckId,
        },
      });
      commit('SET_STAFF_BY_TOURTRUCKID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetStaff({ commit }) {
    commit('SET_STAFF_BY_TOURTRUCKID', []);
  },
  async loadIsBusyTruck({ commit }, truckId) {
    try {
      const response = await axios.get(`${domain}/isBusyTruck?truckId=${truckId}`)
      commit('SET_IS_TRUCK_BUSY', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
  },
  // Exchance Truck section
  async assignExchangeFreeRegularTrucks({ commit }, {
    storeId, startShift, endShift, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/freeRegularTrucksByStoreId`, {
        params: {
          storeId,
          startShift,
          endShift,
        },
      },
        // eslint-disable-next-line function-paren-newline
      );
      commit('SET_FREE_REGULAR_TRUCKS_BY_STORE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },

  async loadExchangeTrucksByPagination({ commit }, {
    registrationPlate, sourceStoreId, destinationStoreId, startShift, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/exchangeTrucksPagination`, {
        params: {
          registrationPlate,
          sourceStoreId,
          destinationStoreId,
          startShift,
          pageSize,
          pageNumber,
        },
      });
      commit('SET_EXCHANGE_TRUCK_BY_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE_EX_TRUCKS', response.data.currentPage);
      commit('SET_TOTALITEMS_TRUCKS_EX_TRUCKS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async assignExchangeTruckStore({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/assignExchangeTrucksToStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Exchange trucks assigned successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Check the status code of the error response
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning exchange trucks.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFreePrivateUser({ commit }, {
    storeId, startShift, endShift, includeTeamLeaders, includeManager, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/freeEmployees`, {
        params: {
          storeId,
          startShift,
          endShift,
          includeTeamLeaders,
          includeManager,
        },
      });
      commit('SET_PRIVATE_USERS', response.data);
      // console.log('SET_PRIVATE_USERS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async assignExchangeTruckStoreToPrivateuser({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/assignExchangeTruckToPrivateUserId`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Exchange truck assigned to private user successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input. Please check your form again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning exchange truck to private user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async cancelEXTruck({ commit }, { exchangeTruckId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/cancelExchangeTruck?exchangeTruckId=${exchangeTruckId}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Exchange truck canceled successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_STATUS', false);
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while canceling the exchange truck.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilteredPlateExchangeTruck({ commit }, {
    sourceStore, destinationStore, startDate, registrationPlate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/exchangeTrucksRegistrationPlateLike`, {
        params: {
          sourceStore,
          destinationStore,
          startDate,
          registrationPlate,
        },
      });
      commit('SET_FILTERED_PLATE_EXCHANGE_TRUCK', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredPlateExchangeTruck({ commit }) {
    commit('SET_FILTERED_PLATE_EXCHANGE_TRUCK', []);
  },
  async loadTruckHistory({ commit }, {
    truckId, date,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain1}/truckStaffByTourTruckIdAndDate`, {
        params: {
          truckId,
          date,
        },
      });
      commit('SET_TRUCK_HISTORY', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
