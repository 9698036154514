<template>
  <div style="width: 100%;height: 20%;">
    <Pie
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'


import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'PieChart',
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => { return [] },
    },
    yearDashboard: {
      default: 'Daily',
      type: String,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  computed: {
    ...mapGetters(['getStatsVacationRequests']),
  },
  watch: {
    getStatsVacationRequests: {
      immediate: true,
      handler() {
        if (this.getStatsVacationRequests) {
          this.updateChartData();
        }
      },
    },
  },
  created() {
    if (this.yearDashboard == 'Daily') {
      this.loadstatsVacationRequests({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
    } else if (this.yearDashboard == 'Weekly') {
      this.loadstatsVacationRequests({
        startDate: moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DD'),
        endDate: moment().isoWeekday(6).endOf('day').format('YYYY-MM-DD'),
      });
    } else if (this.yearDashboard == 'Monthly') {
      this.loadstatsVacationRequests({
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
      });
    }
  },
  methods: {
    ...mapActions(['loadstatsVacationRequests']),
    updateChartData() {
      // Assuming getStatsVacationRequests is an array received from the backend
      this.chartData.labels = this.getStatsVacationRequests.map((item) => { return item.vacationType });
      this.chartData.datasets[0].data = this.getStatsVacationRequests.map((item) => { return item.numberOfEmployyes });

      // Check if all data values are zero
      const allZeros = this.chartData.datasets[0].data.every((value) => { return value === 0 });

      // If all data values are zero, assign a default value to one of the data points
      if (allZeros) {
        this.chartData.datasets[0].data[0] = 1; // Assign a default value of 1
      }

      // You can set backgroundColor as per your requirement
      this.chartData.datasets[0].backgroundColor = ['#41B883', '#E46651', '#00D8FF', '#DD1B16'];
    },
  },
}
</script>
<style scoped lang="scss">
  .container-pie-chart {
    margin-top: 20px;
    padding: 20px;
    width: 45%;
    border: 1px solid;
    border-radius: 30px;
    background-color: #f4f1ed;
  }
</style>
