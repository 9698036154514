import axios from 'axios'
import Swal from 'sweetalert2';
// import Cookies from 'js-cookie';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.userManagementDomain;


const state = {
  loggedInUser: {},
  isLoggedIn: false,
  isLoading: true,
  language: 'en',
};

const getters = {
  getLoggedInUser: (state) => {
    return state.loggedInUser;
  },
  getIsLoggedIn: (state) => {
    return state.isLoggedIn;
  },
  getIsLoading: (state) => {
    return state.isLoading
  },
  getLanguage: (state) => {
    return state.language
  },
}

const mutations = {
  UPDATE_LOGGEDIN_USER(state, payload) {
    state.loggedInUser = payload;
  },
  SET_IS_LOGGED_IN(state, payload) {
    state.isLoggedIn = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LANGUAGE(state, payload) {
    state.language = payload
  },
  SET_IS_ON_VACATION(state, value) {
    state.loggedInUser.isOnVacation = value;
  },
  SET_TOKEN(state, newToken) {
    state.loggedInUser.token = newToken;
  },
};

const actions = {
  async updateIsOnVacation({ commit }, value) {
    commit('SET_IS_ON_VACATION', value);
  },
  async attemptLogin({ commit }, { object, successCallback }) {
    const response = await axios.post(`${domain}/login`, object);

    commit('UPDATE_LOGGEDIN_USER', response.data);
    commit('SET_IS_LOGGED_IN', true)
    successCallback();
  },
  // async loadResetToken({ commit }) {
  //   commit('SET_IS_LOADING', false);
  //   const response = await axios.post(`${domain}/refresh-token`);
  //   console.log(response.data)
  //   commit('SET_TOKEN', response.data);
  //   commit('SET_IS_LOADING', true)
  // },
  async logoutUser({ commit }) {
    commit('SET_IS_LOADING', false);
    commit('UPDATE_LOGGEDIN_USER', {});
    commit('SET_IS_LOGGED_IN', false)
    commit('SET_IS_LOADING', true);
    window.sessionStorage.clear();
  },
  async changePasswordForUser({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.put(`${domain}/changePasswordFirstLogin`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('UPDATE_LOGGEDIN_USER', response.data);
      commit('SET_IS_LOGGED_IN', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Password changed successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid password data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while changing the password.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  test({ commit }) {
    commit('SET_IS_LOGGED_IN', true);
  },
  changeLoadingtoTrue({ commit }, value) {
    commit('SET_IS_LOADING', value)
  },
  changeLanguage({ commit }, lang) {
    commit('SET_LANGUAGE', lang);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
