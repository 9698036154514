<template>
  <section
    class=""
    :style="'padding: 0px 15px 15px 100px; margin-top: 80px'"
  >
    <div style="display: flex; gap: 5px">
      <div style="width: 100% ">
        <div class="budget">
          <div
            class="budgetDetails"
            style="background: linear-gradient(245deg, #55b94a -6.83%, #1970ab 108.99%); cursor: pointer;"
            @click="goToBudgetPreperation()"
          >
            <p>{{ $t("CurrentEarnings") }}</p>
            <h1>
              {{ numbersWithcomma(getTotalCurrentEstimatedEarnings.totalCurrent) }} CHF
            </h1>
          </div>
          <div
            class="budgetDetails"
            style="background: linear-gradient(245deg, #1970ab -6.83%, #1970ab 108.99%); cursor: pointer;"
            @click="goToBudgetPreperation()"
          >
            <p>{{ $t("EstimatedEarnings") }}</p>
            <h1>
              {{ numbersWithcomma(getTotalCurrentEstimatedEarnings.totalEstimate) }} CHF
            </h1>
          </div>
          <div
            class="budgetDetails"
            style="background: #55b94a;  cursor: pointer;"
            @click="goToBudgetPreperation()"
          >
            <p>{{ $t("Difference") }}</p>
            <h1>
              <!-- <ICountUp
                :end-val="collectDifferenceCurrentAndEstimated(
                  getTotalCurrentEstimatedEarnings.totalCurrent,
                  getTotalCurrentEstimatedEarnings.totalEstimate
                )"
                :options="options1"
              /> -->
              {{ numbersWithcomma(collectDifferenceCurrentAndEstimated(
                getTotalCurrentEstimatedEarnings.totalCurrent,
                getTotalCurrentEstimatedEarnings.totalEstimate
              )) }} CHF
            </h1>
          </div>
          <div
            class="filter"
            style="width: 16%; display: flex; flex-direction: column; justify-content: center"
          >
            <b-form-group
              id="input-group-8"
              :label="$t('SelectDate')"
              label-for="input-25"
            >
              <b-form-select
                id="input-5"
                v-model="yearDashboard"
                :clearable="false"
                required
                :options="selectTypeDate"
                label="name"
                style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                :reduce="(option) => option.value"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </div>
        </div>
        <div style="width: 95%">
          <div class="overview">
            <BarChart
              :options="reportsDashboard"
              :css-classes="'container-bar-chart'"
              :height="100"
            />
          </div>
        </div>
        <div style="display: flex; width: 100%">
          <div style="width: 100%">
            <h3 style="text-align: left;">
              {{ $t("Feedback") }}
            </h3>
            <table
              class="team_table"
              style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); font-size: 12px; cursor:pointer"
              @click="goToFeedbacks()"
            >
              <thead>
                <tr>
                  <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                    {{ $t("No.") }}
                  </th>
                  <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                    {{ $t("EmployeeName") }}
                  </th>
                  <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                    {{ $t("Rating") }}
                  </th>
                </tr>
              </thead>

              <tbody
                v-if="getDashboardFeedback.length > 0"
                style="text-align: left;"
              >
                <tr
                  v-for="(item, index) in getDashboardFeedback.slice(0, 5)"
                  :key="index"
                >
                  <td style="padding: 9px">
                    {{ index + 1 }}
                  </td>
                  <td style="padding: 9px">
                    {{ item.userFullName }}
                  </td>
                  <td
                    style="display: flex; justify-content: center"
                    @click.stop
                  >
                    <b-form>
                      <b-form-rating
                        v-model="item.avg"
                        variant="warning"
                        class="mb-2"
                        readonly
                      />
                    </b-form>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else
                style="text-align: left;"
              >
                <h6 style="text-align: left;">
                  {{ $t('noFeedbackInDashboard') }} {{ $t(yearDashboard) }}
                </h6>
              </tbody>
            </table>
          </div>
          <b-container>
            <b-row>
              <b-col>
                <carousel
                  class="custom-carousel"
                  :per-page="1"
                >
                  <slide>
                    <div
                      style="text-align: left;"
                      @click="goToVacation()"
                    >
                      <h3 style="text-align: left;">
                        {{ $t("LeaveRequest") }}
                      </h3>
                      <PieChart :year-dashboard="yearDashboard" />
                    </div>
                  </slide>
                  <!-- <slide class="p-2">
                    <div
                      style="text-align: left;"
                    >
                      <h3
                        style="text-align: left;"
                      >
                        {{ $t('Exchange Properties') }}
                      </h3>
                      <PieChart />
                    </div>
                  </slide> -->
                </carousel>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <div class="stats">
        <div
          class="statsDiv"
          @click="goToRoutes()"
        >
          <h5>{{ $t("RoutesPerDay") }}</h5>
          <div
            class="statsNumber"
            style="display: flex; justify-content: space-between"
          >
            <p>
              <!-- <ICountUp
                :end-val="getTotalRoutesStats.numberOfRoutes"
                :options="options"
              /> -->
              {{ getTotalRoutesStats.numberOfRoutes }}
            </p>
            <img src="../../assets/RoutesDay.svg">
          </div>
          <p>
            {{
              yearDashboard == "Daily" || yearDashboard == "Today"
                ? `${$t('Daily')}`
                : yearDashboard == "Weekly"
                  ? `${$t('Weekly')}`
                  : `${$t('Monthly')}`
            }}
          </p>
        </div>
        <div
          class="statsDiv"
          @click="goToRouteManagement()"
        >
          <h5>{{ $t("EmployeesInRoute") }}</h5>
          <div
            class="statsNumber"
            style="display: flex; justify-content: space-between"
          >
            <p>
              <!-- <ICountUp
                :end-val="getTotalRoutesStats.employeesInRoutes"
                :options="options"
              /> -->
              {{ getTotalRoutesStats.employeesInRoutes }}
            </p>
            <img src="../../assets/EmployeesRoute.svg">
          </div>
          <p>
            {{
              yearDashboard == "Daily" || yearDashboard == "Today"
                ? `${$t('Daily')}`
                : yearDashboard == "Weekly"
                  ? `${$t('Weekly')}`
                  : `${$t('Monthly')}`
            }}
          </p>
        </div>
        <div
          class="statsDiv"
          @click="goToRouteManagement()"
        >
          <h5>{{ $t("TruckInRoute") }}</h5>
          <div
            class="statsNumber"
            style="display: flex; justify-content: space-between"
          >
            <p>
              <!-- <ICountUp
                :end-val="getTotalRoutesStats.truckInRoutes"
                :options="options"
              /> -->
              {{ getTotalRoutesStats.truckInRoutes }}
            </p>
            <img src="../../assets/TruckRoute.svg">
          </div>
          <p>
            {{
              yearDashboard == "Daily" || yearDashboard == "Today"
                ? `${$t('Daily')}`
                : yearDashboard == "Weekly"
                  ? `${$t('Weekly')}`
                  : `${$t('Monthly')}`
            }}
          </p>
        </div>
        <div class="routeDiv">
          <h5>{{ $t("FilialesWithMostEmployeesInRoute") }}</h5>
          <div v-if="getMostEmployeesInRoute.length> 0">
            <div
              v-for="(item, index) in getMostEmployeesInRoute.slice(0, 5)"
              :key="index"
              class="eachRouteDiv"
            >
              <p style="justify-content: space-between; width: 100%">
                <span>
                  {{ index + 1 }}.
                  {{ item.storeName }}
                </span>
                <span>
                  {{ item.nrOfEmployees }}
                </span>
              </p>
            </div>
          </div>
          <div v-else>
            <br>
            <h6>{{ $t('noFilialesInRoute') }} {{ $t(yearDashboard) }}</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
// import ICountUp from 'vue-countup-v2';
import { Carousel, Slide } from 'vue-carousel'
import moment from 'moment-timezone';
import BarChart from '../Charts/barChartDashboard.vue';
import PieChart from '../Charts/pieChart.vue';
import 'vue2-datepicker/index.css';


export default {
  components: {
    // ICountUp,
    BarChart,
    PieChart,
    Carousel,
    Slide,
    // DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['vacationTime'],
  data() {
    return {
      loading: false,
      options1: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '.00 CHF',
      },
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
      orderGroup: {},
      time1: moment().format('YYYY-MM-DD'),
      time2: moment().format('YYYY-MM-DD'),
      rating: 7,
      monthDashboard: null,
      monthTest: moment().format('MMMM'),
      selectType: [
        {
          value: 'Daily',
          name: this.$t('Daily'),
        },
        {
          value: 'Weekly',
          name: this.$t('Weekly'),

        },
        {
          value: 'Monthly',
          name: this.$t('Monthly'),

        },
      ],
      yearDashboard: 'Daily',
      yearsArray: [],
      selectedYear: null,
      startDate: null,
      endDate: null,
      classNameCh: null,
      // form: {},
      form: {
        one: 1,
        two: 2,
        three: 5,
      },
      pageSize: 10,
      pageNumber: 1,
      monthh: null,
      months: [
        {
          value: 0,
          name: 'January',
        },
        {
          value: 1,
          name: 'February',
        },
        {
          value: 2,
          name: 'March',
        },
        {
          value: 3,
          name: 'April',
        },
        {
          value: 4,
          name: 'May',
        },
        {
          value: 5,
          name: 'June',
        },
        {
          value: 6,
          name: 'July',
        },
        {
          value: 7,
          name: 'August',
        },
        {
          value: 8,
          name: 'September',
        },
        {
          value: 9,
          name: 'October',
        },
        {
          value: 10,
          name: 'November',
        },
        {
          value: 11,
          name: 'December',
        },
      ],
      close: false,
      pressedWeek: false,
      storeeeGlnnn: null,
    };
  },
  computed: {
    ...mapGetters([
      'getOrdersGrouppedByState',
      'getLoggedInUser',
      'getIsLoading',
      'getStoreMainboardOverview',
      'getFiliales',
      'getLoggedInUser',
      'getDailyReport',
      'getWeeklyReport',
      'getTotalCurrentEstimatedEarnings',
      'getTotalRoutesStats',
      'getMostEmployeesInRoute',
      'getDashboardFeedback',
      'getDashboardDailyReporting',
    ]),
    reportsDashboard() {
      const monthlyReports = this.getDashboardDailyReporting;
      const sortedReports = monthlyReports.sort((a, b) => { return a.storeName.localeCompare(b.storeName) });
      return sortedReports;
    },
    selectTypeDate() {
      const selectType = [
        { value: 'Daily', name: this.$t('Daily') },
        { value: 'Weekly', name: this.$t('Weekly') },
        { value: 'Monthly', name: this.$t('Monthly') },
      ];

      const transformedOptions = selectType.map((option) => {
        return {
          value: option.value,
          text: option.name,
        };
      });

      return [...transformedOptions];
    },
    isDateDifferenceGreaterThan7Days() {
      if (this.time1 && this.time2) {
        const differenceInMs = Math.abs(new Date(this.time2) - new Date(this.time1));
        const maxDifferenceInMs = 604800000; // 7 days in milliseconds
        return differenceInMs > maxDifferenceInMs;
      }
      return false;
    },
    colorByRate() {
      if (this.rating <= 3) {
        return 'red';
      }
      if (this.rating > 3 && this.rating <= 6) {
        return 'orange';
      }
      return 'green';
    },
  },
  watch: {
    yearDashboard(value) {
      if (value == 'Daily') {
        this.time1 = moment().format('YYYY-MM-DD');
        this.time2 = moment().format('YYYY-MM-DD');
        this.fetchAllRequests();
      } else if (value == 'Weekly') {
        this.time1 = moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DD');
        this.time2 = moment().isoWeekday(6).endOf('day').format('YYYY-MM-DD');
        this.fetchAllRequests();
      } else if (value == 'Monthly') {
        this.time1 = moment().startOf('month').format('YYYY-MM-DD');
        this.time2 = moment().endOf('month').format('YYYY-MM-DD');
        this.fetchAllRequests();
      }
    },
    vacationTime(value) {
      this.loadUpComingVacations({
        storeGLN: this.storeeeGlnnn,
        numberOfDays: value,
      });
    },
  },
  mounted() {
    this.fetchAllRequests();
  },
  methods: {
    ...mapActions([
      // 'loadOrdersGrouppedByState',
      'changeLoadingtoTrue',
      'dailyReportingForStoreByBudget',
      'resetOrdersOverview',
      'loadFilialePagination',
      'loadStoreMainboardOverview',
      'loadUpComingVacations',
      'loadDailyReportForStore',
      'loadWeeklyReportForStore',
      'loadRegularEmployeeOverview',
      'loadTotalCurrentEstimatedEarnings',
      'loadTotalRoutesStats',
      'loadMostEmployeesInRoute',
      'loadDashboardFeedback',
      'loadstatsVacationRequests',
      'loadDashboardDailyReporting',
    ]),
    goToBudgetPreperation() {
      this.$router.push('/daily-overview')
    },
    goToFeedbacks() {
      this.$router.push('/employee-feedback')
    },
    goToVacation() {
      this.$router.push('/leave-request')
    },
    goToRoutes() {
      this.$router.push('/routes')
    },
    goToRouteManagement() {
      this.$router.push('/store-management')
    },
    fetchAllRequests() {
      this.budgetNumbers();
      this.routesStats();
      this.employeesInRoutes();
      this.feedbacks();
      this.vacationRequests();
      this.budgetPreperation();
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    collectDifferenceCurrentAndEstimated(val1, val2) {
      return val1 - val2;
    },
    testt(y, m) {
      return new Date(y, m + 1, 0).getDate();
    },
    async dailyReport() {
      const temp1 = `${this.selectedYear}-${this.monthh.value + 1}-01`;
      const temp2 = `${this.selectedYear}-${this.monthh.value + 1}-${this.testt(
        this.selectedYear,
        this.monthh.value,
      )}`;

      try {
        await this.loadDailyReportForStore({
          startDate: temp1,
          endDate: temp2,
          calculateTotal: true,
        });
      } catch (e) {
        console.log(e);
      }
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    budgetNumbers() {
      this.loadTotalCurrentEstimatedEarnings({
        StartDate: this.time1,
        EndDate: this.time2,
      });
    },
    routesStats() {
      this.loadTotalRoutesStats({
        StartDate: this.time1,
        EndDate: this.time2,
      });
    },
    employeesInRoutes() {
      this.loadMostEmployeesInRoute({
        StartDate: this.time1,
        EndDate: this.time2,
      });
    },
    feedbacks() {
      this.loadDashboardFeedback({
        startDate: this.time1,
        endDate: this.time2,
      });
    },
    vacationRequests() {
      this.loadstatsVacationRequests({
        startDate: this.time1,
        endDate: this.time2,
      });
    },
    budgetPreperation() {
      this.loadDashboardDailyReporting({
        startDate: this.time1,
        endDate: this.time2,
      });
    },
    resetView() {
      this.close = false;
      this.getFiliales = [];
      this.$emit('show-other-component');

      // reset any other relevant data properties
    },
    redirect() {
      this.$router.push('/store-management');
    },

    async stores() {
      this.loadFilialePagination({
        status: true,
        pageNumber: 1,
        pageSize: 100,
      });
    },

    storeMainboardStats() {
      this.loadStoreMainboardOverview({
        storeGLN: null,
        startDate: moment().utc().startOf('month').date(1)
          .format('YYYY-MM-DDT00:00:00.000[Z]'),
        endDate: moment().endOf('month').utc().format('YYYY-MM-DDT23:59:59.999[Z]'),
      });
    },
    obj(value) {
      // if (this.getLoggedInUser == 'TeamLeader') {

      // }
      this.storeeeGlnnn = value.storeGLN;
      this.$emit('click', value.storeGLN);
      this.$emit('show-the-component');

      this.loadStoreMainboardOverview({
        storeGLN: value.storeGLN,
        startDate: '2023-01-01',
        endDate: '2023-04-01',
      });
      this.loadUpComingVacations({
        storeGLN: value.storeGLN,
        numberOfDays: 50,
      });
      this.loadRegularEmployeeOverview({
        storeId: value.storeId,
        status: true,
        isRegular: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.budget {
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 5px;
}

.budgetDetails {
  width: 380px;
  height: 170px;
  flex-shrink: 0;
  border-radius: 9.415px;
  background: linear-gradient(245deg, #55b94a -6.83%, #1970ab 108.99%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.budgetDetails p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.budgetDetails h1 {
  color: #fff;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statsDiv {
  width: 100%;
  height: 190px;
  flex-shrink: 0;
  border-radius: 9.415px;
  border: 2px solid rgba(217, 217, 217, 0.26);
  background: #fff;
  padding: 15px;
}
.routeDiv {
  // height: 147px;
  // flex-shrink: 0; border-radius: 9.415px;
  border: 2px solid rgba(217, 217, 217, 0.26);
  background: #fff;
  padding: 15px;
}

.routeDiv h5 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.routeDiv p {
  color: #000;
  // font-feature-settings: 'clig' off, 'liga' off;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  width: 272px;
  padding: 12px 10px 12px 11px;
  // padding: 5px 5px;
  align-items: flex-start;
  gap: 3px;
  border-radius: 4px;
  background: rgba(165, 165, 165, 0.1);
}

.statsDiv h5 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.statsDiv p {
  color: #a5a5a5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.statsNumber p {
  color: #55b94a;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

.statsNumber img {
  width: 30%;
}
.dateSearch {
  display: flex;
  width: 60%;
}
.mx-datepicker {
  position: relative;
  display: inline-block;
  max-width: 190px;
}

.widhti {
  width: 40%;
  margin-left: 10px;
  margin-top: 2%;
}
.filter{
    width:100%
  }
.hide {
  display: none;
}

p {
  // color: black;
}

table td {
  color: black;
}

.boxes {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
}
//  .box{
//   height: 200px;
//   width: 300px;
//   background-color:gray ;
//   margin: 5px;
//   border-radius: 10px;
//   color: black;
//   display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//  }

.containerr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10% auto 3% auto;
  max-width: 90%;
}

.one {
  background-color: #fff;
  transition: background-color 0.3s ease-in; /* Add a transition effect to the background-color property */
}

.one:hover {
  background-color: #0071ae;
  color: white;
  transition: background-color 0.1s ease-in;
}
.box {
  background-color: #f2f2f2;
  // padding: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  // height: 80px;
  //   width: 15%;
  // border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  // text-align: left;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  //
  min-width: 152px;
  // height: 56px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.main__title {
  text-align: left;
  font-size: 50px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 40px;
}

.hr {
  width: 100%;
  height: 1px;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
}
// .overview {
//   width: 100%;
//   border-radius: 10px;
//   margin-top: 2%;
//   margin-left: 0px;
// }

.row {
  // margin-left: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  word-break: break-word;
}

.nameIcon {
  display: flex;
  justify-content: space-between;
  padding-top: 12%;
}
.blue {
  background-color: lightblue;
  border-color: lightblue;
}
.blue2 {
  border: 2px solid rgb(47, 186, 241);
}
.green2 {
  border: 2px solid #0cb893;
}
.red2 {
  border: 2px solid #eb3449;
}
.green {
  background-image: linear-gradient(135deg, #61d8be 0%, #1e8975 100%);
  border-color: transparent;
}
.red {
  background-image: linear-gradient(135deg, #eb3449 0%, #f35943 100%);
  border-color: transparent;
}
.light-card {
  overflow-wrap: anywhere;
}
.searchButton {
  margin-left: 5px;
  height: 40px;
  margin-top: 22px;
  width: 100%;
}
.all {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15px;
  padding: 5px;
}
.buttons {
  margin-top: 20px;
  // margin-left: 40px;
}
#form-group {
  width: 100%;
  margin-right: 3px;
}
@media screen and (max-width: 1875px) {
  // .main {
  //   padding: 180px 0 0 0 !important;
  //   .container {
  //     margin-right: 0;
  //     margin-left: 0;
  //     max-width: inherit;
  //   }
  // }
  .buttonTime {
    font-size: 12px;
  }
  .inputs {
    font-size: 12px;
  }
  .searchButton {
    font-size: 12px;
    margin-top: 22px;
  }
}
@media screen and (max-width: 1780px) {
  .buttonTime {
    font-size: 12px;
  }
  .inputs {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-left: 22px;
  }
  .searchButton {
    font-size: 12px;
    margin-top: 22px;
    width: 20%;
  }
  .all {
    display: block;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
  #form-group {
    width: 50% !important;
  }
  .budgetDetails {
    width: 300px;
    height: 150px;
    flex-shrink: 0;
    border-radius: 9.415px;
    background: linear-gradient(245deg, #55b94a -6.83%, #1970ab 108.99%);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .budgetDetails h1 {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  .statsDiv {
    flex-shrink: 0;
    height: 150px;
    border-radius: 9.415px;
    border: 2px solid rgba(217, 217, 217, 0.26);
    background: #fff;
    padding: 15px;
  }
  .filter{
    width:200px !important
  }
  .statsDiv p {
    color: #a5a5a5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  .statsNumber p {
    color: #55b94a;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .statsNumber img {
    width: 20%;
  }
}

@media screen and (max-width: 1560px) {
  .budgetDetails {
    width: 250px;
    height: 110px;
    flex-shrink: 0;
    border-radius: 9.415px;
    background: linear-gradient(245deg, #55b94a -6.83%, #1970ab 108.99%);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .statsDiv {
    width: 100%;
    height: 110px;
    flex-shrink: 0;
    border-radius: 9.415px;
    border: 2px solid rgba(217, 217, 217, 0.26);
    background: #fff;
    padding: 15px;
  }
  .budgetDetails h1 {
    color: #fff;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .statsDiv h5 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }
  .statsDiv p {
    color: #a5a5a5;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
  .statsNumber p {
    color: #55b94a;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .statsNumber img {
   width: 13%;
  }
  .routeDiv{

  }
  .filter{
    width: 180px !important
  }
  .routeDiv h5{
    font-size: 15px;
  }
  .eachRouteDiv p{
    font-size: 13px;
  }
}
@media screen and (max-width: 1350px) {
  .budgetDetails {
    width: 240px;
    height: 110px;
    flex-shrink: 0;
    border-radius: 9.415px;
    background: linear-gradient(245deg, #55b94a -6.83%, #1970ab 108.99%);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .statsDiv {
    width: 260px;
    height: 110px;
    flex-shrink: 0;
    border-radius: 9.415px;
    border: 2px solid rgba(217, 217, 217, 0.26);
    background: #fff;
    padding: 15px;
  }
  .budgetDetails h1 {
    color: #fff;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .filter{
    width: 180px !important
  }

  .statsDiv h5 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }
  .statsDiv p {
    color: #a5a5a5;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
  .statsNumber p {
    color: #55b94a;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
}

@media screen and (max-width: 1200px) {
  .dateSearch {
    display: flex;
    width: 70%;
  }

  .dateSearch button {
    margin-top: 22px;
  }
}
@media screen and (max-width: 1150px) {
  .widhti {
    width: 100% !important;
  }
  .d-flex {
    display: block !important;
  }
  .mx-datepicker[data-v-229456f8] {
    position: relative;
    display: inline-block;
    max-width: 160px;
  }
  .main {
    padding: 180px 0 0 0 !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
  }
}

@media screen and (max-width: 1041px) {
  .widhti {
    margin-left: 0px;
  }
}

@media screen and (max-width: 781px) {
  .main {
    .container {
      .light-card__wrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 25px;
      }
      .light-card {
        overflow-wrap: anywhere;
      }
    }
  }
}
</style>
