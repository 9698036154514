/* eslint-disable no-undef */
import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.logisticStoreData;
const domain1 = api.userManagementDomain;
const domain2 = api.tourManagement;

const state = {
  status: false,
  filiales: [],
  filialesForLinks: [],
  extendedFiliale: [],
  extendedFilialePDF: [],
  productsReport: [],
  filteredDresses: [],
  dressSize: [],
  dressColors: [],
  currentPage: 1,
  totalItems: 0,
  filialeById: [],
  filterNameStore: [],
  filialesForTrucks: [],
  filialesForRoutes: [],
  freeManagers: [],
  // calculation hours
  filialeHours: [],
  employeeHours: [],
  calendarVacation: [],
  dailyTaskReportingForStore: [],
  weekTaskReportingForStore: [],
  storesForDropdown: [],
  storeVacationsOverview: [],
  routeMetrics: [],
  routeMetricsReportingForStore: [],
  routeMetricsReportingForStoreSecond: [],
  employee_Planning: [],
  freeEmployeesNew: [],
  filialesForStoreManagement: [],
  monthlyReportingForStoreByBudget: [],
  unAssignedEmployeePlan: [],
  plannedEmployees: [],
};

const getters = {
  getCalendarVacation: (state) => {
    return state.calendarVacation;
  },
  getFiliales: (state) => {
    return state.filiales;
  },
  getFreePlannedEmployees: (state) => {
    return state.plannedEmployees
  },
  getFilialesForLinks: (state) => {
    return state.filialesForLinks;
  },
  getExtendedFiliales: (state) => {
    return state.extendedFiliale;
  },
  getExtendedFilialesPDF: (state) => {
    return state.extendedFilialePDF;
  },
  getTotalItemsForFiliales: (state) => {
    return state.totalItems;
  },
  getFilialebyId: (state) => {
    return state.filialeById;
  },
  getFilterStoreName: (state) => {
    return state.filterNameStore;
  },
  getCurrentPageForStore: (state) => {
    return state.currentPage;
  },
  getFilialesForTrucks: (state) => {
    state.filialesForTrucks.unshift('ALL', 'UNASSIGNED');
    return state.filialesForTrucks
  },
  getFilialesForRoutes: (state) => {
    state.filialesForRoutes.unshift('ALL');
    return state.filialesForRoutes
  },
  getFreeManagers: (state) => {
    return state.freeManagers
  },
  // calculatations of hours
  getFilialeHours: (state) => {
    return state.filialeHours
  },
  getEmployeeHours: (state) => {
    return state.employeeHours
  },
  getDailyTaskReportingForStore: (state) => {
    return state.dailyTaskReportingForStore
  },
  getWeekTaskReportingForStore: (state) => {
    return state.weekTaskReportingForStore
  },
  getStoresForDropdown: (state) => {
    return state.storesForDropdown
  },
  getStoreVacationsOverview: (state) => {
    return state.storeVacationsOverview
  },
  getRouteMetrics: (state) => {
    return state.routeMetrics
  },
  getRouteMetricsReportingForStore: (state) => {
    return state.routeMetricsReportingForStore
  },
  getRouteMetricsReportingForStoreSecond: (state) => {
    return state.routeMetricsReportingForStoreSecond
  },
  getEmployeePlanning: (state) => {
    return state.employee_Planning
  },
  getFreeEmployeesNew: (state) => {
    return state.freeEmployeesNew
  },
  getFilialesForStoreManagement: (state) => {
    return state.filialesForStoreManagement
  },
  getMonthlyReportingForStoreByBudget: (state) => {
    return state.monthlyReportingForStoreByBudget
  },
  getUnAssignedEmployeePlan: (state) => {
    return state.unAssignedEmployeePlan
  },
};

const mutations = {
  SET_CALENDAR_VACATION(state, payload) {
    state.calendarVacation = payload;
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_FILIALES(state, latest) {
    state.filiales = latest;
  },
  SET_FILIALES_FOR_LINKS(state, latest) {
    state.filialesForLinks = latest;
  },
  REMOVE_FREE_FILIALE(state, filiale) {
    state.filiales = state.filiales.filter((u) => { return u.storeId !== filiale.storeId });
  },
  SET_FILIALES_FOR_TRUCKS(state, latest) {
    state.filialesForTrucks = latest;
  },
  SET_FILIALES_FOR_ROUTES(state, latest) {
    state.filialesForRoutes = latest;
  },
  SET_EXTENDED_FILIALES(state, latest) {
    state.extendedFiliale = latest;
  },
  SET_EXTENDED_FILIALES_PDF(state, latest) {
    state.extendedFilialePDF = latest;
  },
  SET_PLANNED_EMPLOYEES(state, latest) {
    state.plannedEmployees = latest
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_TOTALITEMS_FILIALES(state, latest) {
    state.totalItems = latest;
  },
  SET_FILIALE_BY_ID(state, latest) {
    state.filialeById = latest;
  },
  SET_FILTER_STORE_NAME(state, latest) {
    state.filterNameStore = latest;
  },
  SET_FREE_MANAGERS(state, latest) {
    state.freeManagers = latest
  },
  // Calculations
  SET_FILIALE_HOURS(state, latest) {
    state.filialeHours = latest
  },
  SET_EMPLOYEE_HOURS(state, latest) {
    state.employeeHours = latest
  },
  SET_DAILY_TASK_REPORTING_STORE(state, latest) {
    state.dailyTaskReportingForStore = latest
  },
  SET_WEEK_TASK_REPORTING_STORE(state, latest) {
    state.weekTaskReportingForStore = latest
  },
  SET_STORES_FOR_DROPDOWN(state, latest) {
    state.storesForDropdown = latest
  },
  SET_STORE_VACATIONS_OVERVIEW(state, latest) {
    state.storeVacationsOverview = latest
  },
  SET_ROUTE_METRICS(state, latest) {
    state.routeMetrics = latest
  },
  SET_ROUTE_METRICS_REPORTING_FOR_STORE(state, latest) {
    state.routeMetricsReportingForStore = latest
  },
  SET_ROUTE_METRICS_REPORTING_FOR_STORE_SECOND(state, latest) {
    state.routeMetricsReportingForStoreSecond = latest
  },
  SET_EMPLOYEE_PLANNING(state, latest) {
    state.employee_Planning = latest
  },
  SET_FREE_EMPLOYEE_NEW(state, latest) {
    state.freeEmployeesNew = latest
  },
  SET_FILIALES_FOR_STORE_MANAGEMENT(state, latest) {
    state.filialesForStoreManagement = latest
  },
  SET_MONTHLY_REPORTING_STORE_BY_BUDGET(state, latest) {
    state.monthlyReportingForStoreByBudget = latest
  },
  SET_UN_ASSIGNED_EMPLOYEE_PLANNING(state, latest) {
    state.unAssignedEmployeePlan = latest
  },
  // SET_STORE_VACATIONS_OVERVIEW(state, storeVacations) {
  //   const index = state.storeVacationsOverview.findIndex((item) => { return item.storeId === storeVacations.storeId });
  //   if (index > -1) {
  //     state.storeVacationsOverview.splice(index, 1, storeVacations);
  //   } else {
  //     state.storeVacationsOverview.push(storeVacations);
  //   }
  // },
};

const actions = {
  async loadCalculation({ commit }, {
    exactDate, startDate, endDate, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employee_Vacation_Requests`, {
        params: {
          exactDate,
          startDate,
          endDate,
          year,
        },
      });
      commit('SET_CALENDAR_VACATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadPlannedEmployees({ commit }, {
    storeId, startDate, endDate, isCarpenter,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/planned_employees_not_in_tour`, {
        params: {
          storeId,
          startDate,
          endDate,
          isCarpenter,
        },
      });
      commit('SET_PLANNED_EMPLOYEES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async dailyReportingForStoreByBudget({ commit }, {
    month, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dailyReportingForStoreByBudget`, {
        params: {
          month,
          year,
        },
      });
      commit('SET_MONTHLY_REPORTING_STORE_BY_BUDGET', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async loadFilialePagination({ commit }, {
    status, storeName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/storesByPagination`, {
        params: {
          status,
          storeName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_FILIALES', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FILIALES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilialePaginationForRoutes({ commit }, { status, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/storesByPagination`, {
        params: {
          status,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_FILIALES_FOR_ROUTES', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FILIALES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilialePaginationForStoreManagement({ commit }, {
    status, storeName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/storesByPagination`, {
        params: {
          status,
          storeName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_FILIALES_FOR_STORE_MANAGEMENT', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FILIALES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilialePaginationForTrucks({ commit }, { status, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/storesByPagination`, {
        params: {
          status,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_FILIALES_FOR_TRUCKS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FILIALES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadStoresByPagination({ commit }, { storeName, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/extendedStoresByPagination`, {
        params: {
          storeName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_EXTENDED_FILIALES', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FILIALES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadStoresByPaginationPDF({ commit }, { storeName, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/extendedStoresByPagination`, {
        params: {
          storeName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_EXTENDED_FILIALES_PDF', response.data);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FILIALES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadStoresByPaginationForLinks({ commit }, { storeName, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/extendedStoresByPagination`, {
        params: {
          storeName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_FILIALES_FOR_LINKS', response.data);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FILIALES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async addFiliale({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Store added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid store data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async removeCoManagerFromStores({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/removeCoManagerFromStores`, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);

      Swal.fire({
        icon: 'success',
        title: 'Co-manager removed successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while removing the co-manager.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async assignTeamLeader({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/assignEmployeeToStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'TeamLeader assigned successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid TeamLeader data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning the TeamLeader.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async assignManager({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/assignEmployeeToStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Manager assigned successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Manager data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning the Manager.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async assignCoTeamLeader({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/assignEmployeeToStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Co-TeamLeader assigned successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Co-TeamLeader data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning the Co-TeamLeader.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async getFilialeById({ commit }, object) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/StoreById/${object}`);
      commit('SET_FILIALE_BY_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async editFilialeById({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const { storeId, ...updatedObject } = object;
      await axios.put(`${domain}/editStoreById/${object.storeId}`, updatedObject);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Store updated successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid store data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while updating the store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async updateStoreOfEmployee({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/updateStoreOfEmployee`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Store updated successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid store data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while updating the store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilteredStoreName({ commit }, name) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/storeByNameLike?storeName=${name}`);
      commit('SET_FILTER_STORE_NAME', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredFiliale({ commit }) {
    commit('SET_FILTER_STORE_NAME', []);
  },
  async loadFreeManagers({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain1}/managersFullNamesAndIdsByRole`);
      commit('SET_FREE_MANAGERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilialeHours({ commit }, {
    storeGLN, isCarpenter, startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/workingHoursForStore?storeGLN=${storeGLN}&isCarpenter=${isCarpenter}&startDate=${startDate}&endDate=${endDate}`);
      commit('SET_FILIALE_HOURS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadEmployeeHours({ commit }, { employeeUserId, startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/workingHoursForEmployee?employeeUserId=${employeeUserId}&startDate=${startDate}&endDate=${endDate}`);
      commit('SET_EMPLOYEE_HOURS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDailyTaskReportingForStore({ commit }, { startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dailyTourReportingForStoreGruppedByManagers?startDate=${startDate}&endDate=${endDate}`);
      commit('SET_DAILY_TASK_REPORTING_STORE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadWeeklyTaskReportingForStore({ commit }, { startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/weeklyTourReportingForStoreGruppedByManagers?startDate=${startDate}&endDate=${endDate}`);
      commit('SET_WEEK_TASK_REPORTING_STORE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async stores_for_Dropdown({ commit }, { amountOfRecords }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/stores_for_Dropdown?amountOfRecords=${amountOfRecords}`);
      commit('SET_STORES_FOR_DROPDOWN', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async store_vacations_Overview({ commit }, { storeId, year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/store_vacations_Overview?storeId=${storeId}&year=${year}`);
      commit('SET_STORE_VACATIONS_OVERVIEW', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async reset_store_vacations_Overview({ commit }) {
    commit('SET_IS_LOADING', false);
    commit('SET_STORE_VACATIONS_OVERVIEW', []);
    commit('SET_IS_LOADING', true);
  },
  async route_metrics({ commit }, {
    storeGLN, startDate, endDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/route_metrics`, {
        params: {
          storeGLN, startDate, endDate, pageNumber, pageSize,
        },
      });
      commit('SET_ROUTE_METRICS', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async route_metrics_reportingForStore({ commit }, {
    startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/route_metrics_reportingForStore`, {
        params: {
          startDate, endDate,
        },
      });
      commit('SET_ROUTE_METRICS_REPORTING_FOR_STORE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async route_metrics_reportingForStoreSecond({ commit }, {
    startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/route_metrics_reportingForStore`, {
        params: {
          startDate, endDate,
        },
      });
      commit('SET_ROUTE_METRICS_REPORTING_FOR_STORE_SECOND', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async update_employee_number({ commit }, { routeMetricId, newNumber }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/update_employee_number?routeMetricId=${routeMetricId}&newNumber=${newNumber}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async update_completed({ commit }, { routeMetricId, completed }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/update_completed?routeMetricId=${routeMetricId}&completed=${completed}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async update_service_time({ commit }, { routeMetricId, serviceTime }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/update_service_time?routeMetricId=${routeMetricId}&serviceTime=${serviceTime}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async update_route_created({ commit }, { routeMetricId, routeCreated }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/update_route_created?routeMetricId=${routeMetricId}&routeCreated=${routeCreated}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async employeePlanning({ commit }, {
    userId,
    startDate, endDate, isCarpenter,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/employeePlanning`, {
        params: {
          userId,
          startDate,
          endDate,
          isCarpenter,
        },
      });
      commit('SET_EMPLOYEE_PLANNING', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async unAssignedEmployeePlanning({ commit }, {
    userId,
    startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/unAssignedEmployeePlanning`, {
        params: {
          userId,
          startDate,
          endDate,
        },
      });
      commit('SET_UN_ASSIGNED_EMPLOYEE_PLANNING', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetUnAssigned({ commit }) {
    commit('SET_UN_ASSIGNED_EMPLOYEE_PLANNING', []);
  },
  resetEmployeePlanning({ commit }) {
    commit('SET_EMPLOYEE_PLANNING', [])
  },
  async freeEmployees_new({ commit }, {
    userId,
    startShift, endShift,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/freeEmployees_new`, {
        params: {
          userId,
          startShift,
          endShift,
        },
      });
      commit('SET_FREE_EMPLOYEE_NEW', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resetFreeEmployee_new({ commit }) {
    commit('SET_FREE_EMPLOYEE_NEW', []);
  },
  async addEmployeePlanning({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/employeePlanning`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Store added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid store data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deleteEmployeePlanning({ commit }, { employeePlanId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain2}/employeePlanning?employeePlanId=${employeePlanId}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async editEmployeePlanning({ commit }, { obj, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/employeePlanning`, obj);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
