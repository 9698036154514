<template>
  <div id="app">
    <Navbar />
    <div
      v-if="!getIsLoading"
      style="position: absolute; left: 50%; top:50% !important;"
    >
      <Spinner />
    </div>
    <!-- <Sidebar v-if="getLoggedInUser.token" /> -->
    <Sidebar v-if="showSidebar" />
    <router-view />
    <router-link :to="notificationLink">
      <notifications
        group="foo"
        position="bottom right"
        classes="my-style"
      />
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
// import VueCookies from 'vue-cookies'
import connection from './microsoft-signalR/signalR';
import Spinner from './components/Spinner/Spinner.vue';


// import Cookies from 'js-cookie'; // If you're using the js-cookie library

// import connection from './microsoft-signalR/signalR';

export default {
  name: 'Home',
  components: {
    Sidebar,
    Navbar,
    Spinner,
  },
  data() {
    return {
      notificationLink: '',
    }
  },
  computed: {

    ...mapGetters(['getLoggedInUser', 'getIsLoading']),
    showSidebar() {
      return this.$route.path !== '/resetPassword' && this.getLoggedInUser && this.getLoggedInUser.token;
    },
  },
  watch: {
  },
  created() {
    if (!this.getLoggedInUser.token) {
      this.$router.push('/login');
    } else {
      // Start the logout timer and add event listeners
      // this.startLogoutTimer();
      this.addEventListeners();
      this.$nextTick(() => {
        this.connectToSignalR();
      });
    }
    this.changeLoadingtoTrue(true);
  },
  beforeDestroy() {
    this.removeEventListeners();
    this.disconnectFromSignalR();
  },

  mounted() {
    // setInterval(() => {
    //   this.loadResetToken();
    // }, 13 * 60 * 1000);
    // this.changeLoadingtoTrue(true)

    console.log('Appbefore', connection)


    // connection.on('sendBudgetNotificationAlert', (arg) => {
    //   console.log('SendBugetNotificationAlert received:', arg);
    //   this.$notify({
    //     group: 'foo',
    //     title: this.$t('Notification'),
    //     text: `${arg}`,
    //     duration: 5000,
    //     speed: 700,
    //     width: 400,
    //     reverse: false,
    //   });
    //   this.notificationLink = '/daily-overview';
    //   console.log('Navigated to /');
    // });
    // function fulfilled() {
    // // do something on start
    //   console.log('Connection to User Hub Successful');
    // }
    // function rejected() {
    //   console.log('Connection to User Hub Failed');
    // }
    // connection.start().then(fulfilled, rejected);
    // // setTimeout(() => {
    // }, 1000);

    // console.log('Appafter', connection)

    // connection.on('SendBugetNotificationAlert', (arg) => {
    //   this.$notify({
    //     group: 'foo',
    //     title: this.$t('Notification'),
    //     text: `${arg}`,
    //     duration: 5000,
    //     speed: 700,
    //     width: 400,
    //     reverse: false,
    //   });
    //   this.notificationLink = '/daily-overview';
    // });
    // function fulfilled() {
    // // do something on start
    //   console.log('Connection to User Hub Successful');
    // }
    // function rejected() {
    //   console.log('Connection to User Hub Failed');
    // }
    // connection.start().then(fulfilled, rejected);
  },
  methods: {
    ...mapActions(['loadResetToken', 'logoutUser', 'changeLoadingtoTrue', 'changeLanguage', 'changeVisibilityOfSidebar', 'incrementNotificationCount']),
    connectToSignalR() {
      function fulfilled() {
        // do something on start
        console.log('Connection to User Hub Successful');
        setTimeout(() => {
          connection.start().then(fulfilled, this.rejected);
        }, 5000);
      }
      function rejected() {
        console.log('Connection to User Hub Failed');
        // Retry the connection after a delay
        // setTimeout(() => {
        //   connection.start().then(fulfilled, rejected);
        // }, 5000); // 3 seconds delay before retrying
      }

      connection.start().then(fulfilled, rejected);
    },

    disconnectFromSignalR() {
      connection.stop();
    },

    resetLogoutTimer() {
      // Reset the timer
      clearTimeout(this.logoutTimer);
      // this.startLogoutTimer();
    },
    logout() {
      // Clear local storage
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('isLoading');
      localStorage.removeItem('language');
      localStorage.removeItem('isOnVacation');

      // Call logoutUser action and redirect to the login page after it resolves
      this.logoutUser().then(() => {
        this.$router.push('/login');
      });
    },

    // Add event listeners for mousemove and keydown events to reset the timer
    addEventListeners() {
      document.addEventListener('mousemove', this.resetLogoutTimer);
      document.addEventListener('keydown', this.resetLogoutTimer);
    },
    // Remove event listeners when component is destroyed
    removeEventListeners() {
      document.removeEventListener('mousemove', this.resetLogoutTimer);
      document.removeEventListener('keydown', this.resetLogoutTimer);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
