<template>
  <transition name="side">
    <section
      v-if="getIsSidebarHidden == false"
      class="sidebar"
      style="margin-top: 0rem; box-shadow: 0px 3px 5px #e3e3e3; padding-bottom: 10%"
      :class="{
        hide: getIsSidebarHidden == true,
        show: getIsSidebarHidden == false,
      }"
    >
      <div
        button
        style="height: 60px;padding: 0.4rem 1.3rem;border-bottom: 1px solid lightblue;"
      >
        <router-link
          to="/"
          style="background-color: #0071AE !important;padding-bottom: 15px !important;"
        >
          <img
            src="@/assets/DSIIII.png"
            width="40px"
            alt="test"
            class="image1"
          >
          <img
            src="@/assets/DSSSS.svg"
            width="150px"
            alt="test"
            class="image2"
          >
        </router-link>
      </div>
      <b-list-group>

        <b-list-group-item
          v-if="
            getLoggedInUser.role == 'Manager' ||
              getLoggedInUser.role == 'CEO'"
          button
        >
          <router-link
            to="/"
            @click.native="clickLink"
          >
            <!-- <b-icon-columns-gap /> -->
            <img
              src="./../assets/images/dashboardd.svg"
              style="width: 40px; height: 60px; margin-right: 5px;"
              alt="test"
            >
            <p>{{ $t("Dashboard") }}</p>
          </router-link>
        </b-list-group-item>
        <b-list-group-item
          v-if="
            getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'CoTeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
              getLoggedInUser.role == 'CEO'
          "
          v-b-toggle.collapse-3
          button
        >
          <router-link
            to="/nowhere"
            class="disabled-link"
          >
            <!-- <b-icon icon="view-stacked" /> -->
            <img
              style="width: 40px;
                height: 60px; margin-right: 5px;"
              src="./../assets/images/RouteManagement.svg"
              alt=""
            >
            <!-- <p>Store<br>Management</p> -->
            <p>{{ $t("Store") }} <br>{{ $t("Management") }}</p>
          </router-link>
          <div class="collapse-icon">
            <b-icon-chevron-down />
          </div>
        </b-list-group-item>
        <b-collapse
          id="collapse-3"
          class="mt-2"
          accordion="my-accordion"
        >
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'TeamLeader' ||
                getLoggedInUser.role == 'CoTeamLeader' ||
                getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
            @click="reset"
          >
            <router-link
              to="/store-management"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("RouteManagement") }}

              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
            @click="reset"
          >
            <router-link
              to="/store-management-overview"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("FilialeOverview") }}

              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
            @click="reset"
          >
            <router-link
              to="/daily-overview"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("BudgetPreperation") }}

              </p>
            </router-link>
          </b-list-group-item>
          <!-- v-if="false" -->
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
            @click="reset"
          >
            <router-link
              to="/personnel-planning"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("PersonnelPlanning") }}

              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item button>
            <router-link
              to="/tasks"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("Tasks") }}
              </p>
            </router-link>
          </b-list-group-item>
        </b-collapse>
        <b-list-group-item
          v-if="
            // getLoggedInUser.role == 'TeamLeader' ||
            getLoggedInUser.role == 'Manager' ||
              getLoggedInUser.role == 'CEO'
          "
          v-b-toggle.collapse-2
          button
        >
          <router-link
            to="/nowhere"
            class="disabled-link"
          >
            <!-- <b-icon icon="grid3x3-gap-fill" /> -->
            <img
              style="width: 40px; height: 60px; margin-right: 5px;"
              src="./../assets/images/Property.svg"
              alt=""
            >
            <p>{{ $t("Properties") }} </p>
          </router-link>
          <div class="collapse-icon">
            <b-icon-chevron-down />
          </div>
        </b-list-group-item>
        <b-collapse
          id="collapse-2"
          class="mt-2"
          role="tabpanel"
          accordion="my-accordion"
        >
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/filiale"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("Filiales") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item button>
            <router-link
              to="/routes"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("Routes") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item button>
            <router-link
              to="/trucks"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("Trucks") }}
              </p>
            </router-link>
          </b-list-group-item>
        </b-collapse>

        <b-list-group-item
          v-if="
            // getLoggedInUser.role == 'TeamLeader' ||
            getLoggedInUser.role == 'Manager' ||
              getLoggedInUser.role == 'CEO'
          "
          v-b-toggle.collapse-5
          button
        >
          <router-link
            to="/nowhere"
            class="disabled-link"
          >
            <!-- <b-icon
              icon="arrow-left-right
"
            /> -->
            <img
              style="width: 40px; height: 60px; margin-right: 5px;"
              src="./../assets/images/Exchangee.svg"
              alt=""
            >
            <p> {{ $t("ExchangeProperties") }} </p>
          </router-link>
          <div class="collapse-icon">
            <b-icon-chevron-down />
          </div>
        </b-list-group-item>
        <b-collapse
          id="collapse-5"
          class="mt-2"
          role="tabpanel"
          accordion="my-accordion"
        >
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/exchangeTruck"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("ExchangeTrucks") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/exchangeEmployees"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("ExchangeEmployees") }}
              </p>
            </router-link>
          </b-list-group-item>
        </b-collapse>
        <b-list-group-item
          v-if="
            // getLoggedInUser.role == 'TeamLeader' ||
            getLoggedInUser.role == 'Manager' ||
              getLoggedInUser.role == 'CEO'
          "
          v-b-toggle.collapse-4
          button
        >
          <router-link
            to="/nowhere"
            class="disabled-link"
          >
            <!-- <b-icon icon="people-fill" /> -->
            <img
              src="./../assets/images/employee.svg"
              style="width: 40px; height: 60px; margin-right: 5px;"
              alt="test"
            >
            <p>{{ $t("Employees") }}</p>
          </router-link>
          <div class="collapse-icon">
            <b-icon-chevron-down />
          </div>
        </b-list-group-item>
        <b-collapse
          id="collapse-4"
          class="mt-2"
          accordion="my-accordion"
        >
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/users"
              @click.native="clickLink"
            >
              <p class="card-text">
                <!-- {{ $t("Employee") }} {{ $t("Management") }} -->
                {{ $t("EmployeeManagement") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/leave-request"
              @click.native="clickLink"
            >
              <p class="card-text">
                <!-- {{ $t("Vacation") }} <br> {{ $t("Management") }} -->
                {{ $t("VacationManagement") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'TeamLeader' ||
                getLoggedInUser.role == 'CoTeamLeader' ||
                getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/calendar"
              @click.native="clickLink"
            >
              <p>{{ $t("VacationPlan") }}</p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'TeamLeader' ||
                getLoggedInUser.role == 'CoTeamLeader' ||
                getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/employee-feedback"
              @click.native="clickLink"
            >
              <p>{{ $t("Feedback") }}</p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'TeamLeader' ||
                getLoggedInUser.role == 'CoTeamLeader' ||
                getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/employee-payments"
              @click.native="clickLink"
            >
              <p>{{ $t("Payment") }}</p>
            </router-link>
          </b-list-group-item>
          <!-- v-if="false" -->
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'TeamLeader' ||
                getLoggedInUser.role == 'CoTeamLeader' ||
                getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO' ||
                getLoggedInUser.role == 'Admin'
            "
            button
          >
            <router-link
              to="/bank"
              @click.native="clickLink"
            >
              <p>{{ $t("BankHolidays") }}</p>
            </router-link>
          </b-list-group-item>
        </b-collapse>
        <b-list-group-item
          v-if="
            // getLoggedInUser.role == 'TeamLeader' ||
            getLoggedInUser.role == 'Manager' ||
              getLoggedInUser.role == 'CEO'
          "
          v-b-toggle.collapse-6
          button
        >
          <router-link
            to="/nowhere"
            class="disabled-link"
          >
            <!-- <b-icon icon="calculator" /> -->
            <img
              style="width: 40px; height: 60px; margin-right: 5px;"
              src="./../assets/images/calculation.svg"
              alt=""
            >
            <p>{{ $t("Calculations") }}</p>
          </router-link>
          <div class="collapse-icon">
            <b-icon-chevron-down />
          </div>
        </b-list-group-item>
        <b-collapse
          id="collapse-6"
          class="mt-2"
          accordion="my-accordion"
        >
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/filialeCalculation"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("CalculationFiliales") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/EmployeeCalculation"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("CalculationEmployees") }}

              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/saturday-working-hours"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("SaturdayWorkingDays") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/external-working-dayss"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("ExternalWorkingDays") }}
              </p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              // getLoggedInUser.role == 'TeamLeader' ||
              getLoggedInUser.role == 'Manager' ||
                getLoggedInUser.role == 'CEO'
            "
            button
          >
            <router-link
              to="/truckHistory"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("TruckHistory") }}
              </p>
            </router-link>
          </b-list-group-item>
        </b-collapse>
        <b-list-group-item>
          <router-link
            to="/info"
            @click.native="clickLink"
          >
            <!-- <b-icon-info style="width: 30px; height: 50px; margin-right: 5px;" /> -->
            <img
              src="./../assets/images/info.svg"
              style="width: 40px; height: 60px; margin-right: 5px;"
              alt="test"
            >
            <p class="card-text">
              {{ $t("Info") }}
            </p>

          </router-link>
        </b-list-group-item>
      </b-list-group>
    </section>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      width: 0,
    };
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getIsSidebarHidden']),
  },
  watch: {
    width(value) {
      // console.log(value);
      if (value <= 1190) {
        this.changeVisibilityOfSidebar(true);
      } else {
        this.changeVisibilityOfSidebar(false);
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions([
      // 'resetDressColors',
      // 'resetSizes',
      // 'resetProductsByArticleNumber',
      // 'resetCreatedOrdersByOrderNumber',
      'changeVisibilityOfSidebar',
    ]),
    handleResize() {
      this.width = window.innerWidth;
    },
    clickLink() {
      if (this.width <= 1190) {
        this.changeVisibilityOfSidebar(true);
      }
    },
    reset() {
      // this.resetDressColors();
      // this.resetSizes();
      // this.resetProductsByArticleNumber();
    },
  },
};
</script>

<style scoped>


.sidebar .list-group .collapse .list-group-item a.router-link-active {
    color: #0071AE;
    background-color: #e8e8e8;
    display: none;
}
.sidebar:hover .collapse .list-group-item a.router-link-active{
display: flex;
color: #0071AE;
    background-color: #e8e8e8;
}
.sidebar .list-group-item .collapse-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translatey(-50%);
    transition: ease all 0.3s;
    display: none;
}
.sidebar:hover .collapse-icon{
  display: block;
}
.sidebar .list-group .list-group-item p {
  display: none;

}

.sidebar {
  display: block !important;
  overflow: hidden;
  width: 5%;
  transition: width 0.3s ease;
  height:100%;
}

.sidebar:hover .list-group-item p {
  display: block;
  width: 100%;
}

.sidebar:hover {
  width: 15%;
  height: 100vh;
  overflow: auto !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.side-enter-active,
.side-leave-active {
  transition: transform 0.2s ease;
}

.side-enter,
.side-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
.sidebar:hover .image1 {
  display: none !important;
}
.sidebar:hover .image2 {
  display: block !important;
}
.sidebar .image2{
  display:none !important;
}

/* .router-link-active,
  .router-link-exact-active {
    background-color: red;
    color: #fff !important;
  } */
  @media only screen and (max-width:1740px) {
    .sidebar{
      width: 5%;
    }

  }
  @media only screen and (max-width: 1190px) {
  .sidebar {
    display: none;
  }
  .hide {
  display: flex !important;
}
}

@media only screen and (max-width: 1190px) {
  .sidebar .list-group .collapse .list-group-item a.router-link-active {
    color: #242F6E;
    background-color: #e8e8e8;
    display: block;
}
.sidebar:hover .collapse .list-group-item a.router-link-active{
display: none;
color: #242F6E;
    background-color: #e8e8e8;
}
.sidebar .list-group-item .collapse-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translatey(-50%);
    transition: ease all 0.3s;
    display: block;
}
.sidebar:hover .collapse-icon{
  display: none;
}
.sidebar .list-group .list-group-item p {
  display: block;

}

.sidebar {
  display: block !important;
  overflow: auto;
  width: 25% !important;
  transition: width 0.3s ease;
}

.sidebar:hover .list-group-item p {
  display: block;
  width: 100%;
}

.sidebar:hover {
  width: 25% !important;
  height: 100vh;
  display: none;
}
  .sidebar {
    display: block !important;
  }
}
@media screen and (max-width: 1500px){
  .sidebar{
      width: 5%;
    }
}

@media screen and (max-width: 1400px){
  .sidebar{
      width: 6%;
    }
}
/* @media screen and (max-width: 1300px){
  .sidebar{
      width: 9% !important;
    }
}
@media screen and (max-width: 1400px){
  .sidebar{
      width: 8% !important;
    }
} */
/*
@media screen and (max-width: 1500px){
  .sidebar{
      width: 6% !important;
    }
}
@media screen and (max-width: 1700px){
  .sidebar{
      width: 5% !important;
    }
} */
</style>
