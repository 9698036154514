<!-- eslint-disable no-restricted-globals -->
<template>
  <section
    v-if="!$route.path.includes('login')"
    class="navbar"
  >
    <div style="display: flex; align-items: center;">
      <b-icon-list
        v-if="!$route.path.includes('login')"
        font-scale="2"
        class="burger-menu"
        @click="showOrHideSidebar"
      />
      <!-- <router-link :to="!getLoggedInUser ? '/': '/login'">
        <b-img
          :src="require('../assets/images/logistiklogo.svg')"
          alt="Named color image (red)"
          width="110"
        />
      </router-link> -->
      <div
        style="width: 120%; height: 120%;padding-left: 100px;"
        @click="pushHome"
      >
        <!-- <div class="hrr" /> -->


        <div class="container final">
          <div class="letter F">
            S
          </div>
          <div class="letter l">
            P
          </div>
          <div class="letter i">
            I
          </div>
          <div class="letter p">
            M
          </div>
          <!-- <div class="for">
          for
        </div> -->
          <div class="gsap">
            Logistik
          </div>
        </div>

      </div>
    </div>
    <p style="color: white">
      {{ notification }}
    </p>
    <div
      style="display:flex; color: #ffffff !important"
      class="albanianFlag"
    >
      <b-dropdown
        :text="language"
        variant="btnLanguage"
        style="margin-right:20px;"
        class="dropdown-language"
      >
        <b-dropdown-item
          variant="dropLanguage"
          @click="changeL('en')"
        >
          English
        </b-dropdown-item>
        <div>
          <b-dropdown-item
            variant="dropLanguage"
            @click="changeL('sq')"
          >
            Shqip
          </b-dropdown-item>
          <b-dropdown-item
            variant="dropLanguage"
            @click="changeL('de')"
          >
            Deutsch
          </b-dropdown-item>
          <b-dropdown-item
            variant="dropLanguage"
            @click="changeL('fr')"
          >
            French
          </b-dropdown-item>
        </div>
      </b-dropdown>
      <router-link
        v-if="!getLoggedInUser.role"
        to="/login"
      >

        <!-- <button
        v-b-modal.modal-material
        type="button"
        class="button"
        style="border-radius:6px"
      >

        Log in

      </button> -->
      </router-link>
      <div
        v-else-if="getLoggedInUser.isFirstLoginExecuted == true && $route.path !== '/resetPassword'"
        style="display:flex; align-items:center; justify-content:space-between; text-align: left; color: #FFFFFF"
      >
        <p style="margin-bottom: 0px; padding-right: 20px !important">
          {{ $t('Welcome') }} <strong>{{ getLoggedInUser.firstName }} {{ getLoggedInUser.lastName }}
          </strong></p>
        <font-awesome-icon
          class="leave-icon"
          font-scale="1.7"
          icon="fa-solid fa-arrow-right-from-bracket"
          @click="logout"
        />
        <button
          type="button"
          class="sign-out"
          style="border-radius:6px"
          @click="logout"
        >
          {{ $t('SignOut') }}
          <font-awesome-icon
            style="margin-left:5px"
            icon="fa-solid fa-arrow-right-from-bracket"
          />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { gsap } from 'gsap';

import { Flip } from 'gsap/Flip';
// import albanianFlag from '../assets/images/Flag_of_Albania.png'
// import englishFlag from '../assets/images/United-Kingdom-icon.png'
// import connection from '../microsoft-signalR/signalR'

export default {
  data() {
    return {
      notification: '',
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getLanguage', 'getIsSidebarHidden', 'getIsSidebarHidden']),
    language() {
      if (this.getLanguage === 'en') {
        return 'English'
      } if (this.getLanguage === 'de') {
        return 'Deutsch'
      } if (this.getLanguage === 'fr') {
        return 'French'
      }
      return 'Shqip'
    },
  },
  mounted() {
    gsap.registerPlugin(Flip);

    const layouts = ['final', 'plain', 'columns', 'grid'];
    const container = document.querySelector('.container');
    let curLayout = 0; // index of the current layout

    function nextState() {
      const state = Flip.getState('.letter, .for, .gsap', { props: 'color,backgroundColor', simple: true });
      // console.log('Current Layout:', curLayout);
      // console.log('State:', state);

      container.classList.remove(layouts[curLayout]);
      curLayout = (curLayout + 1) % layouts.length;
      // console.log('Next Layout:', curLayout);

      container.classList.add(layouts[curLayout]);

      Flip.from(state, {
        absolute: true,
        stagger: 0.07,
        duration: 0.7,
        ease: 'power2.inOut',
        spin: curLayout === 0,
        simple: true,
        onEnter: (elements, animation) => {
          // console.log('Entering Animation');
          return gsap.fromTo(elements, { opacity: 0 }, { opacity: 1, delay: animation.duration() - 0.1 });
        },
        onLeave: (elements) => {
          // console.log('Leaving Animation');
          return gsap.to(elements, { opacity: 0 });
        },
      });

      gsap.delayedCall(curLayout === 0 ? 3.5 : 1.5, nextState);
    }

    gsap.delayedCall(1, nextState);
  },
  methods: {
    ...mapActions(['logoutUser', 'changeLanguage', 'changeVisibilityOfSidebar', 'loadLogOut']),
    logout() {
      // connection.stop()
      this.loadLogOut()
      this.logoutUser()
      this.$router.push('/login');
    },
    pushHome() {
      this.$router.push('/')
    },
    changeL(lang) {
      this.changeLanguage(lang)
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang
      }
    },
    showOrHideSidebar() {
      if (this.getIsSidebarHidden == true) {
        this.changeVisibilityOfSidebar(false);
      } else {
        this.changeVisibilityOfSidebar(true);
      }
    },
  },

}
</script>

<style lang="scss" scoped>



* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Mori";
  font-weight: 300;
  height: 100vh;
  overflow: hidden;
}
.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.container.grid, .container.columns {
  align-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;
}

.letter {
  text-align: left;
  color: white;
  font-size: 0.8vmax;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
}
.container.grid .letter {
  flex-basis: 50%;
}
.container.columns .letter {
  flex-basis: 25%;
}
.for, .gsap {
  font-size: 0.8vmax;
  color: white;
}
.for {
  padding: 2px 1.6vmax;
  font-weight: 300;
  display: none;
}
.gsap {
  padding: 2px 5px;
  font-weight: 600;
  display: none;
}
.container.final .for, .container.final .gsap {
  display: block;
}
.F {
  background: #0071AE;
}
.l {
  background:#3B8DC4;
}
.i {
   background:#569FCA;
}
.p {
  background: #55B94A;
}
.container.plain .letter {
  background: transparent;
  color: white;
  padding: 0;
}

.logo {
  position: fixed;
  width: 100px;
  bottom: 20px;
  right: 30px;
}








.leave-icon {
  display: none !important;
  cursor: pointer;
}

.albanianFlag li {
  display: flex;

  img {
    float: right;
  }
}

.burger-menu {
  display: none !important;
  color: #fff;
  cursor: pointer;

}

@media only screen and (max-width: 1190px) {
  .burger-menu {
    display: inline-block !important;
    color: #fff;
    cursor: pointer;
  }

  .burger-menu:hover {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 655px) {
  .navbar {
    padding: 0 0.75rem !important;
  }

  .sign-out {
    font-size: 12px;
  }

  p {
    font-size: 12px;
  }

  .dropdown-language {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 655px) {
  .sign-out {
    display: none;
  }

  .leave-icon {
    display: inline-block !important;
  }
}
</style>
