import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Modules

import users from './modules/users';
import route from './modules/route';
import teams from './modules/teams';
import trackAndTrace from './modules/trackAndTrace';
import authentication from './modules/authentication';
import filiale from './modules/filiale';
import style from './modules/style';
import vacation from './modules/vacation'
import truck from './modules/truck'

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['authentication'],
      storage: window.localStorage,
    }),
  ],
  modules: {
    users,
    teams,
    trackAndTrace,
    authentication,
    style,
    filiale,
    route,
    vacation,
    truck,
  },
  strict: process.env.DEV,
});
