import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.vacation;
const domain2 = api.logisticStoreData;

const state = {
  status: false,
  canton: null,
  vacationRequests: [],
  hasVacation: false,
  remainingDays: null,
  vacationOverview: null,
  ourBankHolidays: [],
};

const getters = {
  getBankHolidays: (state) => {
    return state.ourBankHolidays
  },
  getCantons: (state) => {
    return state.canton;
  },
  getHasVacation: (state) => {
    return state.hasVacation;
  },
  getVacationRequests: (state) => {
    return state.vacationRequests;
  },
  getVacationOverview: (state) => {
    return state.vacationOverview;
  },
  getRemainingDays: (state) => {
    return state.remainingDays;
  },
  // getActiveVacationRequests: (state) => {
  //   return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
  //     return vacation.cancelled == false && new Date(vacation.endVacation) >= new Date();
  //   });
  // },
  // getInActiveVacationRequests: (state) => {
  //   return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
  //     return vacation.cancelled == true || new Date(vacation.endVacation) <= new Date();
  //   });
  // },
  // getPastAndFutureVacations: (state) => {
  //   return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
  //     return vacation.cancelled == false
  //   });
  // },
  getActiveVacationRequests: (state) => {
    if (state.vacationRequests && state.vacationRequests.vacationRequestDtos) {
      return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
        return vacation.cancelled == false && new Date(vacation.endVacation) >= new Date();
      });
    }
    return [];
  },

  getInActiveVacationRequests: (state) => {
    if (state.vacationRequests && state.vacationRequests.vacationRequestDtos) {
      return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
        return vacation.cancelled == true || new Date(vacation.endVacation) <= new Date();
      });
    }
    return [];
  },

  getPastAndFutureVacations: (state) => {
    if (state.vacationRequests && state.vacationRequests.vacationRequestDtos) {
      return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
        return vacation.cancelled == false;
      });
    }
    return [];
  },
};

const mutations = {
  SET_BANKHOLIDAYS(state, payload) {
    state.ourBankHolidays = payload;
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CANTON(state, latest) {
    state.canton = latest;
  },
  SET_VACATION_REQUESTS(state, latest) {
    state.vacationRequests = latest;
  },
  SET_VACATION_OVERVIEW(state, latest) {
    state.vacationOverview = latest;
  },
  SET_ALREADY_REQUESTED_VACATION(state, latest) {
    state.hasVacation = latest;
  },
  SET_REMAINING_DAYS(state, latest) {
    state.remainingDays = latest;
  },
};

const actions = {
  async loadCountryStates({ commit }) {
    commit('SET_IS_LOADING', false)
    try {
      const countryid = api.countryId; // Production
      // const countryid = '88FB770B-F6DF-43DE-0849-08DB3737520F'; // Production
      // const countryid = '9080b1da-4da4-4b25-5c93-08db31f6edb2'; // Development
      const response = await axios.get(`${domain}/countryStates?countryId=${countryid}`);
      commit('SET_CANTON', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadBankHolidays({ commit }, { countryStateId, year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/bankHolidays`, {
        params: {
          countryStateId,
          year,
        },
      });
      commit('SET_BANKHOLIDAYS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async leaveRequest({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addVacationRequest`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Leave request added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the leave request.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async bankHolidays({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);

    try {
      await axios.post(`${domain}/addBankHolidays`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank holiday added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the bank holiday.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async copyBankHolidays({ commit },
    { countryStateId, year, successCallback }) {
    commit('SET_IS_LOADING', false);

    try {
      await axios.post(`${domain}/copyBankHolidays?countryStateId=${countryStateId}&year=${year}`);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async editBankHolidays({ commit }, { bankHolidayId, object }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editBankHolidays/{id}?bankHolidayId=${bankHolidayId}`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Bank holiday edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while editing bank holidays',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async addCoManagerToStores({ commit }, object) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/addCoManagerToStores`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Co-manager added to stores successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while adding the co-manager to stores.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async getAlreadyRequestedVacation({ commit }, {
    employeeUserId, startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/alreadyRequestedVacation`, {
        params: {
          employeeUserId,
          startDate,
          endDate,
        },
      })
      commit('SET_ALREADY_REQUESTED_VACATION', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async cancelVacation({ commit }, vacationRequestId) {
    const params = {
      vacationRequestId,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/cancellVacationRequest`, null, { params });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Vacation cancelled successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while cancelling the vacation.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async editVacationDays({ commit }, {
    employeeUserId, numberOfDays, year, successCallback,
  }) {
    const params = {
      employeeUserId,
      numberOfDays,
      year,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editVacationDays`, null, { params });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Vacation days edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback(); // Call the successCallback if it exists
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while editing the vacation days.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async moveVacationDays({ commit }, {
    employeeUserId, currentYear, newYear, amountOfDays, successCallback,
  }) {
    const params = {
      employeeUserId,
      currentYear,
      newYear,
      amountOfDays,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/moveVacationDays`, null, { params });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Vacation days moved successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback(); // Call the successCallback if it exists
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while moving the vacation days.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async payVacationDays({ commit }, {
    employeeUserId, numberOfDays, year, successCallback,
  }) {
    const params = {
      employeeUserId,
      numberOfDays,
      year,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/payVacationDays`, null, { params });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Vacation days have been paid successfully.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback(); // Call the successCallback if it exists
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while moving the vacation days.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
  },
  async loadVacationRequests({ commit }, { employeeId, year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/vocationRequests`, {
        params: {
          employeeId,
          year,
        },
      });
      commit('SET_VACATION_REQUESTS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadVacationOverview({ commit }, { managerId, date }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/vacations_Overview`, {
        params: {
          managerId,
          date,
        },
      });
      commit('SET_VACATION_OVERVIEW', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async remainingDaysOfEmployee({ commit }, { employeeUserId, year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/remainingDaysOfEmployee`, {
        params: {
          employeeUserId,
          year,
        },
      });
      commit('SET_REMAINING_DAYS', response.data);
    } catch (error) {
      if (error.response.status === 400) {
        commit('SET_REMAINING_DAYS', 0);
        // Show error message using SweetAlert if needed
        Swal.fire({
          icon: 'warning',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        // Handle other error responses
        // Show error message using SweetAlert if needed
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while loading remaining vacation days.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
