import axios from 'axios';
import { api } from '../../domainConfig';


/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.userManagementDomain;
const domain2 = api.stockManagementDomain;

const state = {
  status: false,
  teams: [],
  filteredTeams: [],
  teamsOrders: [],
  articles: [],
  teamOverview: {},
  freeSewers: [],
  freePatronists: [],
  freeTeamLeaders: [],
  currentPage: 1,
  totalItems: 0,
};

const getters = {
  getTeams: (state) => {
    return state.teams;
  },
  getFilteredTeams: (state) => {
    return state.filteredTeams;
  },
  getTeamOverview: (state) => {
    return state.teamOverview;
  },

  getTeamOrders: (state) => {
    return state.teamsOrders;
  },

  getArticles: (state) => {
    return state.articles;
  },

  getFreeSewers: (state) => {
    return state.freeSewers;
  },

  getFreePatronists: (state) => {
    return state.freePatronists;
  },

  getFreeTeamLeaders: (state) => {
    return state.freeTeamLeaders;
  },

  getCurrentPageForTeams: (state) => {
    return state.currentPage;
  },

  //   getFilteredUsers: (state) => {
  //     return state.fileteredUsers;
  //   },

  getTotalItemsForTeams: (state) => {
    return state.totalItems;
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_TEAMS(state, latest) {
    state.teams = latest;
  },
  SET_FILTERED_TEAMS(state, latest) {
    state.filteredTeams = latest;
  },
  SET_ARTICLES(state, latest) {
    state.articles = latest;
  },
  SET_TEAM_ORDERS(state, latest) {
    state.teamsOrders = latest;
  },
  SET_TEAM_OVERVIEW(state, latest) {
    state.teamOverview = latest;
  },
  SET_FREE_SEWERS(state, latest) {
    state.freeSewers = latest;
  },
  SET_FREE_PATRONISTS(state, latest) {
    state.freePatronists = latest;
  },
  SET_FREE_TEAM_LEADERS(state, latest) {
    state.freeTeamLeaders = latest;
  },
  //   SET_FILTERED_USERS(state, latest) {
  //     state.fileteredUsers = latest;
  //   },
  SET_TOTALITEMS_TEAMS(state, latest) {
    state.totalItems = latest;
  },
};
const actions = {
  async getTeamsPagination({ commit }, { pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getTeamNamesPagination`, {
        params: {
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TEAMS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_TEAMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getOrderItemsManagementBy({ commit }, { orderNr, id }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/orderItemsManagementBy/${orderNr}/${id}`);
      commit('SET_ARTICLES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getOrderById({ commit }, id) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/ordersBy/${id}`);
      commit('SET_TEAM_ORDERS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  resergetOrderById({ commit }) {
    commit('SET_TEAM_ORDERS', [])
  },
  async teamByTeamNameId({ commit }, teamNameId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/teamOverviewByTeamNameId/${teamNameId}`);
      commit('SET_TEAM_OVERVIEW', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async addTeam({ commit }, { object, successCallback }) {
    // const objToJson = JSON.stringify(object)
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addTeam`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },

  async loadFreeSewerPagination({ commit }, { pageNumber, pageSize } = {}) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getFreeSewersPagination`, {
        params: {
          pageNumber,
          pageSize,
        },
      })
      commit('SET_FREE_SEWERS', response.data.items)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async loadFreePatronistPagination({ commit }, { pageNumber, pageSize } = {}) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/freePatternMakersPagination`, {
        params: {
          pageNumber,
          pageSize,
        },
      });
      commit('SET_FREE_PATRONISTS', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async loadFreeTeamLeadersPagination({ commit }, { pageNumber, pageSize } = {}) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getFreeTeamLeadersPagination`, {
        params: {
          pageNumber,
          pageSize,
        },
      })
      commit('SET_FREE_TEAM_LEADERS', response.data.items)
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },

  async editTeam({ commit }, { teamNameId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editTeamByTeamNameId/${teamNameId}`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async getTeamNameByNameLike({ commit }, teamName) {
    if (teamName == '') {
      commit('SET_FILTERED_TEAMS', []);
      return;
    }
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getTeamNamesByNameLike?teamName=${teamName}`);
      commit('SET_FILTERED_TEAMS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async assignOrderItemToTeamLeader({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/assignOrderItemToTeam`, object)
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
